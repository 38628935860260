import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareDefaultHeaders } from '../../api'
import { DescriptionGenerationRequest, DescriptionGenerationResponse, LiveEventAnalysis, LiveEventAnalysisMutation } from '../types/types'

const internalOpenAIChatApiUrl = window.GR_API_URLS.API_URL_AI_ANALYST_INTERNAL
const liveEventsPredictionsUrl = window.GR_API_URLS.API_URL_LIVE_EVENTS_PREDICTIONS
/**
 * An API module combining endpoints from different services. To take advantage of redux toolkit query
 * management tools, the endpoints affecting each other need to be defined within the same module.
 */
export const internalCombinedApi = createApi({
  reducerPath: 'internalCombinedApi',
  baseQuery: fetchBaseQuery({ prepareHeaders: prepareDefaultHeaders }),
  tagTypes: ['liveEventAnalysis'],
  endpoints: (builder) => ({
    getLiveEventAnalysis: builder.query<LiveEventAnalysis | undefined, { eventId: string }>({
      query: ({ eventId }) => ({
        url: `${internalOpenAIChatApiUrl}/eventAnalysis`,
        method: 'GET',
        params: {
          eventId,
        },
      }),
      providesTags: (result, error, { eventId }) => [{ type: 'liveEventAnalysis', id: eventId }],
    }),
    getMotivationDescription: builder.query<DescriptionGenerationResponse, DescriptionGenerationRequest>({
      query: (data) => ({
        url: `${liveEventsPredictionsUrl}/motivation/generate`,
        method: 'POST',
        body: data,
      }),
    }),
    getMotivationAnalysis: builder.query<LiveEventAnalysisMutation, { eventId: string }>({
      query: ({ eventId }) => ({
        url: `${liveEventsPredictionsUrl}/live-events/${eventId}`,
        method: 'GET',
      }),
      providesTags: ['liveEventAnalysis'],
    }),
    postLiveEventAnalysis: builder.mutation<LiveEventAnalysisMutation, LiveEventAnalysisMutation>({
      query: (data) => ({
        url: `${liveEventsPredictionsUrl}/live-events`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['liveEventAnalysis'],
    }),
  }),
})

export const { useGetLiveEventAnalysisQuery, useGetMotivationDescriptionQuery, useGetMotivationAnalysisQuery, usePostLiveEventAnalysisMutation } =
  internalCombinedApi
