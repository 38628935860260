import React, { useMemo } from 'react'

import { CheckCircle } from '@mui/icons-material'
import { Collapse, Grid, Typography } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import languageService from '../../../../services/LanguageService'
import { MotivationType } from '../../../market-explorer/types/MotivationType'
import { MotivationIcon } from '../../utils/svgs'
import './MotivationTypeBadge.scss'

interface Props {
  type: MotivationType
  selected?: boolean
  active?: boolean
  onSelect?: (motivationType: MotivationType) => void
  alignLeft?: boolean
  description?: string
}

export const MotivationTypeBadge: React.FC<Props> = ({ type, selected, active = false, alignLeft, description, onSelect }) => {
  const motivationTypeName = languageService.getTranslation('motivations', type)
  const motivationTypeDescription = description ? description : languageService.getTranslation('motivations', `${type}_description`)

  const componentClassNames = useMemo(() => {
    let classNames = 'MotivationTypeBadge'

    if (onSelect) {
      classNames = `${classNames} MotivationTypeBadge--Selectable`
    }

    if (alignLeft) {
      classNames = `${classNames} MotivationTypeBadge--AlignLeft`
    }

    if (active) {
      classNames = `${classNames} MotivationTypeBadge--Active`
    }

    return classNames
  }, [active, alignLeft, onSelect])

  return (
    <div className={componentClassNames}>
      <GRTooltip
        content={
          <>
            <Typography variant="h3">
              <Grid container spacing={1.5} alignItems={'center'}>
                <Grid item>
                  <MotivationIcon motivation={type} />
                </Grid>
                <Grid item xs>
                  {motivationTypeName}
                </Grid>
              </Grid>
            </Typography>
            <p style={{ marginBottom: '0px' }} dangerouslySetInnerHTML={{ __html: motivationTypeDescription }}></p>
          </>
        }
      >
        <div
          className={selected ? 'MotivationTypeBadge__Content MotivationTypeBadge__Content--Selected' : 'MotivationTypeBadge__Content'}
          onClick={() => onSelect && onSelect(type)}
        >
          <Grid container alignItems={'center'}>
            <Grid item>
              <div className="MotivationTypeBadge__Type">
                <MotivationIcon motivation={type} />
              </div>
            </Grid>

            <Grid item xs>
              {motivationTypeName}
            </Grid>

            <Collapse in={active} orientation="horizontal" unmountOnExit easing={{ enter: 'linear', exit: 'linear' }} timeout={100}>
              <Grid item>
                <div className="MotivationTypeBadge__Active">{active && <CheckCircle />}</div>
              </Grid>
            </Collapse>
          </Grid>
        </div>
      </GRTooltip>
    </div>
  )
}
