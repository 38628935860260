import React, { useMemo } from 'react'

import { CheckCircle } from '@mui/icons-material'
import { Box, Collapse, Fade, Grid, Typography } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import languageService from '../../../../services/LanguageService'
import { MotivationKey } from '../../../market-explorer/types/MotivationType'
import { motivationArchetypeImages } from '../../utils/MotivationArchetypeImages'
import './MotivationArchetypeBadge.scss'

interface MotivationArchetypeBadgeProps {
  motivationKey: MotivationKey
  selected?: boolean
  active?: boolean
  description?: string // This will replace the description for the motivation archetype tooltip
  onSelect?(motivationKey: MotivationKey): void
}

const MotivationArchetypeBadge: React.FC<MotivationArchetypeBadgeProps> = ({ motivationKey, selected, active = false, description, onSelect }) => {
  const archetypeName = languageService.getTranslation('motivations', motivationKey)
  const archetypeDescription = description ? description : languageService.getTranslation('motivations', `${motivationKey}_description`)
  const classNames = useMemo(() => {
    let classNames = 'MotivationArchetypeBadge'

    if (onSelect) {
      classNames = `${classNames} MotivationArchetypeBadge--Selectable`
    }

    if (selected) {
      classNames = `${classNames} MotivationArchetypeBadge--Selected`
    }

    if (active) {
      classNames = `${classNames} MotivationArchetypeBadge--Active`
    }

    return classNames
  }, [active, onSelect, selected])

  return (
    <div className={classNames} onClick={() => onSelect && onSelect(motivationKey)}>
      <GRTooltip
        disableMaxWidth
        content={
          <Grid container maxWidth="600px">
            <Grid item width="400px">
              <Box>
                <Typography variant="h3">{archetypeName}</Typography>
                <p dangerouslySetInnerHTML={{ __html: archetypeDescription }}></p>
              </Box>
            </Grid>
            <Grid item alignContent="center">
              <img className="MotivationArchetypeBadge__Tooltip__Image" src={motivationArchetypeImages[motivationKey]} alt={motivationKey} />
            </Grid>
          </Grid>
        }
      >
        <Grid className="MotivationArchetypeBadge__Content" container alignItems="center">
          <Grid item>
            <img className="MotivationArchetypeBadge__Image" src={motivationArchetypeImages[motivationKey]} alt={motivationKey} />
          </Grid>

          <Grid item>
            <div>{archetypeName}</div>
          </Grid>

          <Collapse in={active} orientation="horizontal" unmountOnExit easing={{ enter: 'linear', exit: 'linear' }} timeout={100}>
            <Grid item className="MotivationArchetypeBadge--Active__Icon">
              <div>
                {active && (
                  <Fade in={active} timeout={400}>
                    <CheckCircle />
                  </Fade>
                )}
              </div>
            </Grid>
          </Collapse>
        </Grid>
      </GRTooltip>
    </div>
  )
}

export default MotivationArchetypeBadge
