import { FC, ReactNode } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'

import ScreenshotsModalContainer from '../../features/news/components/ScreenshotsModalContainer/ScreenshotsModalContainer'
import ReportCardPreview from '../../features/reports/components/ReportCardPreview/ReportCardPreview'
import { UpdateModalContainer } from '../../features/update-modal/components/UpdateModalContainer/UpdateModalContainer'
import { InternalRoute } from '../../internal/components/InternalRoute/InternalRoute'
import { AnalystNoteEditPage } from '../../internal/pages/live-events-tracking/AnalystNoteEditPage'
import { SearchGamesPage } from '../../internal/pages/live-events-tracking/SearchGamesPage'
import { TrackedGameAnalystNotesPage } from '../../internal/pages/live-events-tracking/TrackedGameAnalystNotesPage'
import TrackedGameEventAnalysisPage from '../../internal/pages/live-events-tracking/TrackedGameEventAnalysisPage'
import { TrackedGameEventEditPage } from '../../internal/pages/live-events-tracking/TrackedGameEventEditPage'
import { TrackedGameEventsOverviewPage } from '../../internal/pages/live-events-tracking/TrackedGameEventsOverviewPage'
import { TrackedGameEventsPage } from '../../internal/pages/live-events-tracking/TrackedGameEventsPage'
import { TrackedGamesPage } from '../../internal/pages/live-events-tracking/TrackedGamesPage'
import PageService from '../../services/PageService'
import AIAnalystPage from '../AIAnalyst/AIAnalystPage'
import CompareGamesPage from '../CompareGamesPage/CompareGamesPage'
import DailyInsightsPage from '../DailyInsightsPage/DailyInsightsPage'
import DataGlossaryPage from '../DataGlossaryPage/DataGlossaryPage'
import FollowedGamesPage from '../FollowedGamesPage/FollowedGamesPage'
import FtueVideosPage from '../FtueVideosPage/FtueVideosPage'
import GameAnalyzerDetailPage from '../GameAnalyzerDetailPage/GameAnalyzerDetailPage'
import GameAnalyzerPage from '../GameAnalyzerPage/GameAnalyzerPage'
import GamePage from '../GamePage/GamePage'
import GamePageWrapper from '../GamePage/GamePageWrapper'
import GenreEssentialsPage from '../GenreEssentialsPage/GenreEssentialsPage'
import ImplementationExamplesPage from '../ImplementationExamplesPage/ImplementationExamplesPage'
import InvitesPage from '../InvitesPage/InvitesPage'
import LiveEventsTrackerPage from '../LiveEventsTrackerPage/LiveEventsTrackerPage'
import MarketExplorerPage from '../MarketExplorerPage/MarketExplorerPage'
import MarketSharePage from '../MarketSharePage/MarketSharePage'
import MarketTrendsPage from '../MarketTrendsPage/MarketTrendsPage'
import MyProfilePage from '../MyProfilePage/MyProfilePage'
import OrganizationPage from '../OrganizationPage/OrganizationPage'
import OrganizationUsersPage from '../OrganizationUsersPage/OrganizationUsersPage'
import { RegisterPage } from '../RegisterPage/RegisterPage'
import ReportsPage from '../ReportsPage/ReportsPage'
import SingleUnifiedNewsEntryPage from '../SingleUnifiedNewsEntryPage/SingleUnifiedNewsEntryPage'
import SlackIntegrationPage from '../SlackIntegrationPage/SlackIntegrationPage'
import SlackPreferencesPage from '../SlackPreferencesPage/SlackPreferencesPage'
import { SoftLaunchPage } from '../SoftLaunchPage/SoftLaunchPage'
import TopGrossingPage from '../TopGrossingPage/TopGrossingPage'
import { UnsubscribeDailyDigestPage } from '../UnsubscribeDailyDigestPage/UnsubscribeDailyDigestPage'
import { UpdateImpactsPage } from '../UpdateImpactsPage/UpdateImpactsPage'
import VisualExplorerPage from '../VisualExplorerPage/VisualExplorerPage'
import { AuthenticatedRoute } from './AuthenticatedRoute/AuthenticatedRoute'
import { LiveEventsEventDialogContainerDeprecated, LiveEventsEventStatisticsDialogContainerDeprecated } from './DeprecatedModalRoutes/DeprecatedModalRoutes'
import { UnauthenticatedRoute } from './UnauthenticatedRoute/UnauthenticatedRoute'

type AppRoutesProps = {
  children?: ReactNode
}

export const AppRoutes: FC<AppRoutesProps> = ({ children }) => {
  return (
    <Routes>
      <Route path="/unsubscribe-daily-digest" element={<UnsubscribeDailyDigestPage />} />
      <Route element={<UnauthenticatedRoute />}>
        <Route path="/register" element={<RegisterPage />} />
      </Route>
      <Route element={<AuthenticatedRoute />}>
        <Route path="/internal" element={<InternalRoute />}>
          {PageService.internalPages.map((page) => {
            const Page = internalPageIdToComponentMap[page.id]
            return Page && <Route key={page.id} path={page.href} element={<Page />} />
          })}
          <Route index element={<Navigate replace to="live-events-tracking" />} />
        </Route>

        {PageService.pages.map((page) => {
          const Page = pageIdToComponentMap[page.id]
          return (
            Page && (
              <Route
                key={page.id}
                path={page.href}
                element={
                  <ModalOutlet>
                    <Page />
                  </ModalOutlet>
                }
              >
                {/* TODO: game update modal and screenshot modal need to be refactored to use url search params instead of path params */}
                <Route path="gameupdate/:gameId/:gameVersion/:marketIso/*" element={<UpdateModalContainer />} />
                <Route path="screenshots/:screenshots/:shot?/*" element={<ScreenshotsModalContainer />} />

                {/* Routes for depracated path based modals for redirecting to new url search params based modals */}
                <Route
                  path="liveevent/:trackedGameId/:liveEventTypeId/liveEventId?/:liveEventId?/tab?/:tab?/screenshotIndex?/:screenshotIndex?/*"
                  element={<LiveEventsEventDialogContainerDeprecated />}
                />
                <Route path="liveeventstatistics/:gameIds/:liveEventTypeId/:tab/*" element={<LiveEventsEventStatisticsDialogContainerDeprecated />}></Route>
              </Route>
            )
          )
        })}
        <Route index element={<Navigate replace to="/daily-insights" />} />
        <Route path={'*'} element={<Navigate replace to="/daily-insights" />} />
      </Route>
    </Routes>
  )
}

const ModalOutlet: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <>
      <Outlet />
      {children}
    </>
  )
}

// mapping of pageId to page component
const pageIdToComponentMap: { [pageId: string]: FC | undefined } = {
  'data-glossary': DataGlossaryPage,
  'data-glossary-detail': DataGlossaryPage,
  'daily-insights': DailyInsightsPage,
  'genre-essentials': GenreEssentialsPage,
  'top-grossing': TopGrossingPage,
  'soft-launch': SoftLaunchPage,
  'market-explorer': MarketExplorerPage,
  'market-share': MarketSharePage,
  'visual-explorer': VisualExplorerPage,
  reports: ReportsPage,
  'report-detail': ReportCardPreview,
  'market-trends': MarketTrendsPage,
  'live-events-tracker': LiveEventsTrackerPage,
  'ai-analyst': AIAnalystPage,
  'followed-games': FollowedGamesPage,
  'compare-games': CompareGamesPage,
  'game-update-impacts': UpdateImpactsPage,
  'implementation-examples': ImplementationExamplesPage,
  'ftue-videos': FtueVideosPage,
  'game-analyzer': GameAnalyzerPage,
  'game-analyzer-detail': GameAnalyzerDetailPage,
  'news-card': SingleUnifiedNewsEntryPage,
  game: GamePage,
  'game-tab': GamePageWrapper,
  'my-profile': MyProfilePage,
  organization: OrganizationPage,
  'organization-users': OrganizationUsersPage,
  slack: SlackIntegrationPage,
  'slack-preferences': SlackPreferencesPage,
  invites: InvitesPage,
}

// mapping of internal pageId to page component
const internalPageIdToComponentMap: { [pageId: string]: FC | undefined } = {
  'internal-tracked-games': TrackedGamesPage,
  'internal-tracked-game-events': TrackedGameEventsPage,
  'internal-tracked-game-events-edit-event': TrackedGameEventEditPage,
  'internal-tracked-games-search': SearchGamesPage,
  'internal-tracked-game-analyst-notes': TrackedGameAnalystNotesPage,
  'internal-tracked-game-analyst-note-edit': AnalystNoteEditPage,
  'internal-tracked-game-events-overview': TrackedGameEventsOverviewPage,
  'internal-tracked-game-event-analysis': TrackedGameEventAnalysisPage,
}
