import { endOfDay, endOfMonth, isAfter, isThisMonth, startOfDay, startOfMonth, sub } from 'date-fns'
import { useMemo } from 'react'

import { Tag, useGetEventsForTrackedGamesQuery } from '../../../api/core'
import { endOfDayTimestamp } from '../../../helpers/endOfDayTimestamp'
import { useError } from '../../../hooks/useError'
import { Currency } from '../../../internal/types/Currency'
import languageService from '../../../services/LanguageService'
import { useLiveEventsTrackerAccessCheck } from '../../account/hooks/roleHooks'
import { useCurrentMarket } from '../../markets'
import { NewsEntryType } from '../../news/types/UnifiedNewsEntry'
import { TopIAP } from '../../top-IAP/types/TopIAP'
import { resolveGameVersionFeatures } from '../../update-impacts/hooks/useUpdateImpacts'
import { defaultTrackedEventsStartTimestamp, lockedLiveEventsTrackerEndTimestamp, lockedLiveEventsTrackerStartTimestamp } from '../const/const'
import { GameTopIAPsByDate } from '../types/GameTopIAPsByDate'
import { GameVersion } from '../types/GameVersion'
import { LiveEventAppearance } from '../types/LiveEventAppearance'
import { PerformanceChangesByOptionValue } from '../types/PerformanceChangesByOption'
import { PerformanceEffect, PerformanceEffectType } from '../types/PerformanceEffect'
import {
  TrackingEventsByGame,
  TrackingEvent,
  PerformanceChangeField,
  PerformanceChanges,
  LiveEventsFeatureData,
  TrackingEventByGame,
} from '../types/TrackingEvents'
import { getLiveEventDurations, getLiveEventPricePoints, getPricePointUnitValue, resolveTrackingEventMaxTime } from '../utils/utils'
import { useTrackedEventsEndTimestamp } from './useTrackedEventsEndTimestamp'

type UseTrackedGamesEventsHookParams = {
  gameIds: string[]
  startTimestamp: number
  endTimestamp: number
  marketIso: string
  eventTypeId?: string
}

export const useTrackedGamesEvents = ({ gameIds, startTimestamp, endTimestamp, marketIso, eventTypeId }: UseTrackedGamesEventsHookParams) => {
  const trackedEventsQuery = useGetEventsForTrackedGamesQuery(
    {
      gameIds,
      startTimestamp,
      endTimestamp,
      marketIso,
    },
    { skip: !gameIds || gameIds.length === 0 }
  )

  useError({ error: trackedEventsQuery.error })

  const filteredEvents = useMemo(
    () =>
      eventTypeId
        ? Object.entries(trackedEventsQuery.data?.events || {}).reduce((acc, [gameId, trackingEvents]) => {
            acc[gameId] = trackingEvents.filter((trackingEvent) => trackingEvent.typeId === eventTypeId)
            return acc
          }, {} as TrackingEventByGame)
        : trackedEventsQuery.data?.events,
    [eventTypeId, trackedEventsQuery.data?.events]
  )

  return useMemo(
    () => ({
      events: filteredEvents || {},
      performanceChanges: trackedEventsQuery.data?.performanceChanges || {},
      performanceValues: trackedEventsQuery.data?.performanceValues || {},
      versions: trackedEventsQuery.data?.versions || {},
      featuresData: trackedEventsQuery.data?.featureData || {},
      topIAPs: trackedEventsQuery.data?.topIAPs || {},
      comments: trackedEventsQuery.data?.comments || {},
      isLoading: trackedEventsQuery.isLoading || trackedEventsQuery.isFetching,
      isError: trackedEventsQuery.isError,
    }),
    [
      filteredEvents,
      trackedEventsQuery.data?.performanceChanges,
      trackedEventsQuery.data?.performanceValues,
      trackedEventsQuery.data?.versions,
      trackedEventsQuery.data?.featureData,
      trackedEventsQuery.data?.topIAPs,
      trackedEventsQuery.data?.comments,
      trackedEventsQuery.isLoading,
      trackedEventsQuery.isFetching,
      trackedEventsQuery.isError,
    ]
  )
}

type DefaultLiveEventsHookParams = {
  trackedGameIds: string[]
}

/**
 * Hook for fetching tracked events for given games with default time range
 */
export const useDefaultLiveEvents = ({ trackedGameIds }: DefaultLiveEventsHookParams) => {
  const hasAccessToLiveEventsTracker = useLiveEventsTrackerAccessCheck()
  const { currentMarketIso } = useCurrentMarket()

  const endTimestampBasedOnInternalRole = useTrackedEventsEndTimestamp(endOfDayTimestamp())

  const trackedEvents = useTrackedGamesEvents({
    gameIds: trackedGameIds,
    startTimestamp: hasAccessToLiveEventsTracker ? defaultTrackedEventsStartTimestamp.getTime() : lockedLiveEventsTrackerStartTimestamp,
    endTimestamp: hasAccessToLiveEventsTracker ? endTimestampBasedOnInternalRole : lockedLiveEventsTrackerEndTimestamp,
    marketIso: currentMarketIso,
  })

  return trackedEvents
}

type UseFilteredTrackedGamesEventsParams = {
  currencies: {
    [id: string]: {
      currencies: Currency[]
    }
  }
  data: ReturnType<typeof useDefaultLiveEvents>
  selectedLiveEventTags: Tag[]
  selectedLiveEventSecondaryTags: Tag[]
  selectedLiveEventsDurationIdsMap: { [durationId: string]: boolean }
  selectedLiveEventsAppearanceIdsMap: { [appearanceId: string]: boolean }
  selectedLiveEventsPricePointIdsMap: { [pricePointId: string]: boolean }
  selectedMotivationsIdsMap: { [motivationType: string]: boolean }
  selectedArchetypesIdsMap: { [motivationKey: string]: boolean }
  performanceEffectThreshold: number
  performanceChangesBy: PerformanceChangesByOptionValue
  eventNameSearchValue: string
}

/**
 * Hook for filtering tracked games events
 */
export const useFilteredTrackedGamesEvents = ({
  currencies,
  data,
  selectedLiveEventTags,
  selectedLiveEventSecondaryTags,
  selectedLiveEventsDurationIdsMap,
  selectedLiveEventsAppearanceIdsMap,
  selectedLiveEventsPricePointIdsMap,
  selectedMotivationsIdsMap,
  selectedArchetypesIdsMap,
  performanceEffectThreshold,
  performanceChangesBy,
  eventNameSearchValue,
}: UseFilteredTrackedGamesEventsParams) => {
  // filter tracking events by event tags & durations
  const filteredEvents = useMemo(() => {
    const durations = getLiveEventDurations().filter((duration) => {
      return selectedLiveEventsDurationIdsMap[duration.id]
    })

    const pricePointFilters = getLiveEventPricePoints().filter((pricePoint) => {
      return selectedLiveEventsPricePointIdsMap[pricePoint.id]
    })

    const events = data.events || {}

    const filteredTrackingEventsByGame: TrackingEventsByGame = { events: {} }
    Object.keys(events).forEach((gameId) => {
      const gameEvents: TrackingEvent[] = []
      const gameEventAppearanceCountMap: {
        [eventId: string]: number
      } = {}
      const gameEventFirstHighlightedAdded: { [eventId: string]: boolean } = {}

      if (events[gameId]) {
        events[gameId].forEach((trackingEvent) => {
          gameEventAppearanceCountMap[trackingEvent.eventId]
            ? (gameEventAppearanceCountMap[trackingEvent.eventId] = gameEventAppearanceCountMap[trackingEvent.eventId] + 1)
            : (gameEventAppearanceCountMap[trackingEvent.eventId] = 1)
        })

        events[gameId].forEach((trackingEvent) => {
          const eventStartDate = new Date(trackingEvent.start).setHours(0, 0, 0, 0)
          const eventEndDate = new Date(trackingEvent.end).setHours(23, 59, 59, 59)
          const eventDurationInDays = Math.ceil(Math.abs(eventStartDate - eventEndDate) / (1000 * 60 * 60 * 24))
          let eventDurationMatchesSelection = Object.keys(selectedLiveEventsDurationIdsMap).length === 0 ? true : false

          durations.forEach((duration) => {
            if (eventDurationInDays >= duration.start && eventDurationInDays <= duration.end) {
              eventDurationMatchesSelection = true
            }
          })

          const gameEventAppearanceValid =
            Object.keys(selectedLiveEventsAppearanceIdsMap).length === 0 ||
            (selectedLiveEventsAppearanceIdsMap[LiveEventAppearance.NewEventTypes] &&
              trackingEvent.highlighted &&
              !gameEventFirstHighlightedAdded[trackingEvent.eventId]) ||
            (selectedLiveEventsAppearanceIdsMap[LiveEventAppearance.RecurringEventTypes] && gameEventAppearanceCountMap[trackingEvent.eventId] > 1) ||
            (selectedLiveEventsAppearanceIdsMap[LiveEventAppearance.NonrecurringEventTypes] && gameEventAppearanceCountMap[trackingEvent.eventId] === 1)
              ? true
              : false

          gameEventFirstHighlightedAdded[trackingEvent.eventId] = true

          let eventPricePointMatchesSelection = Object.keys(selectedLiveEventsPricePointIdsMap).length === 0 ? true : false
          const eventPricePoints = trackingEvent.pricePoints || []

          if (!eventPricePointMatchesSelection && eventPricePoints.length !== 0) {
            const gameCurrencies = currencies[gameId]
            //convert all price point values in usd
            const eventPricePointValues = eventPricePoints.map((pricePoint) => {
              if (gameCurrencies) {
                const unitPrice = getPricePointUnitValue(pricePoint, gameCurrencies.currencies)
                if (unitPrice?.value) {
                  return unitPrice.value
                }
              }
              return pricePoint.value
            })
            //check if any of the event price point values match the selected price ranges
            pricePointFilters.forEach((filter) => {
              if (eventPricePointValues.find((pricePoint) => pricePoint >= filter.start && pricePoint <= filter.end)) {
                eventPricePointMatchesSelection = true
              }
            })
          }

          let gameEventSecondaryTagsValid = selectedLiveEventSecondaryTags.length === 0 ? true : false

          if (!gameEventSecondaryTagsValid) {
            // check if any of the event secondary tags match the selected secondary tags
            selectedLiveEventSecondaryTags.forEach((selectedSecondaryTag) => {
              if (trackingEvent.tags.find((secondaryTag) => secondaryTag === selectedSecondaryTag.id)) {
                gameEventSecondaryTagsValid = true
              }
            })
          }

          let eventMotivationsValid = Object.keys(selectedMotivationsIdsMap).length === 0 ? true : false

          if (!eventMotivationsValid && trackingEvent.motivations?.motivations) {
            trackingEvent.motivations.motivations.forEach((motivationType) => {
              if (selectedMotivationsIdsMap[motivationType.key]) {
                eventMotivationsValid = true
              }
            })
          }

          let eventArchetypesValid = Object.keys(selectedArchetypesIdsMap).length === 0 ? true : false

          if (!eventArchetypesValid && trackingEvent.motivations?.motivations) {
            trackingEvent.motivations.archetypes.forEach((motivationKey) => {
              if (selectedArchetypesIdsMap[motivationKey.key]) {
                eventArchetypesValid = true
              }
            })
          }

          if (
            (selectedLiveEventTags.length === 0 || selectedLiveEventTags.find((tag) => tag.id === trackingEvent.typeId)) &&
            (Object.keys(selectedLiveEventsDurationIdsMap).length === 0 || eventDurationMatchesSelection) &&
            gameEventAppearanceValid &&
            eventPricePointMatchesSelection &&
            gameEventSecondaryTagsValid &&
            eventMotivationsValid &&
            eventArchetypesValid &&
            (!eventNameSearchValue || trackingEvent.name.toLowerCase().includes(eventNameSearchValue.toLowerCase()))
          ) {
            gameEvents.push(trackingEvent)
          }
        })
      }

      filteredTrackingEventsByGame.events[gameId] = gameEvents.sort((a, b) => (a.start < b.start ? -1 : 1))
    })

    return filteredTrackingEventsByGame.events
  }, [
    data.events,
    selectedLiveEventsDurationIdsMap,
    selectedLiveEventsPricePointIdsMap,
    selectedLiveEventsAppearanceIdsMap,
    currencies,
    selectedLiveEventSecondaryTags,
    selectedMotivationsIdsMap,
    selectedArchetypesIdsMap,
    selectedLiveEventTags,
    eventNameSearchValue,
  ])

  // map and filter estimate changes by threshold and reference period
  const filteredEstimateChanges = useMemo(() => {
    const performanceChanges = data.performanceChanges || {}
    return Object.entries(performanceChanges).reduce((acc, [gameId, performanceChanges]) => {
      const performanceEffects: PerformanceEffect[] = (performanceChanges || []).flatMap((estimateChange) => {
        const parsedDate = new Date(estimateChange.date)
        const startTime = startOfDay(parsedDate).getTime()
        const endTime = endOfDay(parsedDate).getTime()
        return [
          {
            type: PerformanceEffectType.Download,
            value: resolvePerformanceChangeValue(estimateChange?.performanceChanges, performanceChangesBy, PerformanceEffectType.Download, parsedDate),
            startTime,
            endTime,
          },
          {
            type: PerformanceEffectType.Revenue,
            value: resolvePerformanceChangeValue(estimateChange?.performanceChanges, performanceChangesBy, PerformanceEffectType.Revenue, parsedDate),
            startTime,
            endTime,
          },
          {
            type: PerformanceEffectType.DAU,
            value: resolvePerformanceChangeValue(estimateChange?.performanceChanges, performanceChangesBy, PerformanceEffectType.DAU, parsedDate),
            startTime,
            endTime,
          },
          {
            type: PerformanceEffectType.MAU,
            value: resolvePerformanceChangeValue(estimateChange?.performanceChanges, performanceChangesBy, PerformanceEffectType.MAU, parsedDate),
            startTime: startOfMonth(parsedDate).getTime(),
            endTime: endOfMonth(parsedDate).getTime(),
          },
        ]
      })

      const filteredPerformanceEffects = performanceEffects
        .filter((performanceEffect) => isFinite(performanceEffect.value) && !isNaN(performanceEffect.value) && performanceEffect.value)
        .filter((performanceEffect) => Math.abs(performanceEffect.value) >= performanceEffectThreshold)

      acc[gameId] = filteredPerformanceEffects

      return acc
    }, {} as { [gameId: string]: PerformanceEffect[] })
  }, [data.performanceChanges, /*mauChanges,*/ performanceChangesBy, performanceEffectThreshold])

  // filter game versions
  const filteredVersions = useMemo(() => {
    const versionsByGame: { [gameId: string]: GameVersion[] } = {}

    Object.keys(data.versions).forEach((gameId) => {
      if (!versionsByGame[gameId]) {
        versionsByGame[gameId] = []
      }

      data?.versions[gameId]?.forEach((versionData) => {
        const parsedDate = new Date(versionData.versionReleaseDate)
        const startTime = startOfDay(parsedDate).getTime()
        const endTime = endOfDay(parsedDate).getTime()

        // Check changed features and updated features
        const featuresData: LiveEventsFeatureData | undefined = data.featuresData?.[gameId]?.[versionData.version]
        const features = featuresData?.type !== NewsEntryType.GpsSystem && featuresData?.features ? featuresData?.features : []
        const screenshots =
          featuresData?.type === NewsEntryType.GpsUpdate && featuresData?.screenshots
            ? featuresData?.screenshots.map((screenshot) => {
                const screenshotFeatures = screenshot.features || ({} as { [featureLegacyId: string]: number }[])
                return {
                  ...screenshot,
                  features: Object.entries(screenshotFeatures).map(([featureId, choiceId]) => {
                    return {
                      featureLegacyId: parseInt(featureId, 10),
                      featureName: languageService.getTranslation('features', featureId),
                      featureName_translation: languageService.getTranslation('features', featureId),
                      choiceLegacyId: choiceId,
                      choiceName: languageService.getTranslation('choices', choiceId + ''),
                      choiceName_translation: languageService.getTranslation('choices', choiceId + ''),
                    }
                  }),
                }
              })
            : []

        const { changedFeatures, updatedFeatures } = resolveGameVersionFeatures(features, screenshots, versionData.versionReleaseDate)

        const gameVersion: GameVersion = {
          startTime,
          endTime,
          gameId,
          appId: versionData.appId,
          version: versionData.version,
          featureChanges: changedFeatures.length > 0 ? true : false,
          updatedFeatures: updatedFeatures.length > 0 ? true : false,
        }

        versionsByGame[gameId].push(gameVersion)
      })

      // filter out potential duplicates
      versionsByGame[gameId] = [...new Map(versionsByGame[gameId].map((item) => [item['version'], item])).values()]
    })

    return versionsByGame
  }, [data.versions, data.featuresData])

  // filter game Top IAPs
  const filteredTopIAPsForGame = useMemo(() => {
    const topIAPsForGame: { [gameId: string]: GameTopIAPsByDate[] } = {}

    Object.keys(data.topIAPs).forEach((gameId) => {
      const gameTopIAPsByDate: GameTopIAPsByDate[] = []
      let previousIAPs: TopIAP[] = []

      data?.topIAPs[gameId]?.forEach((gameTopIAPs) => {
        const parsedDate = new Date(gameTopIAPs.date)
        const startTime = startOfDay(parsedDate).getTime()
        const endTime = endOfDay(parsedDate).getTime()

        const gameTopIAPsByDateData: GameTopIAPsByDate = {
          startTime: startTime,
          endTime: endTime,
          gameId: gameId,
          appId: gameTopIAPs.appId,
          currentIAPs: gameTopIAPs.topIAP,
          previousIAPs: previousIAPs,
          hasIAPChanges: false,
          IAPChanges: {},
        }

        const usedIndices: number[] = []

        gameTopIAPsByDateData.currentIAPs.forEach((currentIAP, index) => {
          let IAPChange = 0
          const currentIAPId = currentIAP.name + currentIAP.price
          const previousIAPIndex =
            gameTopIAPsByDateData.previousIAPs?.findIndex((previousIAP, index) => {
              return currentIAP.name === previousIAP.name && currentIAP.price === previousIAP.price && !usedIndices.includes(index)
            }) || 0

          // Handling potential duplicate IAPs with same name and price
          if (previousIAPIndex !== -1) {
            usedIndices.push(previousIAPIndex)
          }

          const isNew = previousIAPIndex === -1 ? true : false

          if (currentIAPId && previousIAPIndex) {
            IAPChange = previousIAPIndex === -1 ? 0 : previousIAPIndex - index
            if (IAPChange > 0 || IAPChange < 0 || isNew) {
              gameTopIAPsByDateData.hasIAPChanges = true
            }
          } else if (isNew) {
            gameTopIAPsByDateData.hasIAPChanges = true
          }

          gameTopIAPsByDateData.IAPChanges[index] = {
            change: IAPChange,
            isNew: isNew,
          }
        })

        gameTopIAPsByDate.push(gameTopIAPsByDateData)
        previousIAPs = gameTopIAPs.topIAP
      })

      topIAPsForGame[gameId] = gameTopIAPsByDate
    })

    return topIAPsForGame
  }, [data.topIAPs])

  // resolve maximum time from data timestamps
  const maxTime = useMemo(() => {
    let result = 0
    const allEvents = Object.values(data.events).flat()

    if (allEvents.length === 0) {
      result = startOfDay(new Date()).getTime()
    } else {
      result = resolveTrackingEventMaxTime(allEvents, result)
    }

    return new Date(result)
  }, [data.events])

  return {
    events: filteredEvents,
    performanceEffects: filteredEstimateChanges,
    gameVersions: filteredVersions,
    gameTopIAPs: filteredTopIAPsForGame,
    comments: data.comments,
    maxTime: maxTime,
    performanceValues: data.performanceValues,
  }
}

/**
 * Resolves a revenue or download value from performanceChanges object. Impact values
 * will be resolved to 0 for the first impact value days counting from the current day.
 */
const resolvePerformanceChangeValue = (
  performanceChanges: PerformanceChanges = {},
  performanceChangesBy: PerformanceChangesByOptionValue,
  performanceEffectType: PerformanceEffectType,
  referenceDate: Date
) => {
  const isImpactValue = performanceChangesBy.includes('Impact')
  const isPeakValue = !isImpactValue
  // extract the number of days from the field name
  const impactDays = +(performanceChangesBy.match(/\d+/gm)?.[0] || 0)

  if (isImpactValue && isAfter(endOfDay(referenceDate), sub(startOfDay(new Date()), { days: impactDays + 1 }))) {
    // impact values cannot be shown for first 7/14/30 days from current date
    return 0
  } else if (isPeakValue && isAfter(endOfDay(referenceDate), sub(startOfDay(new Date()), { days: 1 }))) {
    // peak values cannot be shown from yesterday onwards
    return 0
  } else if (performanceEffectType === PerformanceEffectType.MAU && isThisMonth(referenceDate)) {
    // MAU values cannot be shown for the current month
    return 0
  } else {
    return performanceChanges?.[performanceChangesByValueToEstimateChangeField?.[performanceEffectType]?.[performanceChangesBy]] || 0
  }
}

// mapping of PerformanceChangesByOptionValue values to response data keys
const performanceChangesByValueToEstimateChangeField = {
  [PerformanceEffectType.Revenue]: {
    [PerformanceChangesByOptionValue.Impact_7_days]: PerformanceChangeField.REVENUE_IMPACT_DAY_7_CHANGE,
    [PerformanceChangesByOptionValue.Impact_14_days]: PerformanceChangeField.REVENUE_IMPACT_DAY_14_CHANGE,
    [PerformanceChangesByOptionValue.Impact_30_days]: PerformanceChangeField.REVENUE_IMPACT_DAY_30_CHANGE,
    [PerformanceChangesByOptionValue.Peak_1_days]: PerformanceChangeField.REVENUE_DAY_1_CHANGE,
    [PerformanceChangesByOptionValue.Peak_7_days]: PerformanceChangeField.REVENUE_DAY_7_CHANGE,
    [PerformanceChangesByOptionValue.Peak_14_days]: PerformanceChangeField.REVENUE_DAY_14_CHANGE,
    [PerformanceChangesByOptionValue.Peak_30_days]: PerformanceChangeField.REVENUE_DAY_30_CHANGE,
  },
  [PerformanceEffectType.Download]: {
    [PerformanceChangesByOptionValue.Impact_7_days]: PerformanceChangeField.DOWNLOAD_IMPACT_DAY_7_CHANGE,
    [PerformanceChangesByOptionValue.Impact_14_days]: PerformanceChangeField.DOWNLOAD_IMPACT_DAY_14_CHANGE,
    [PerformanceChangesByOptionValue.Impact_30_days]: PerformanceChangeField.DOWNLOAD_IMPACT_DAY_30_CHANGE,
    [PerformanceChangesByOptionValue.Peak_1_days]: PerformanceChangeField.DOWNLOAD_DAY_1_CHANGE,
    [PerformanceChangesByOptionValue.Peak_7_days]: PerformanceChangeField.DOWNLOAD_DAY_7_CHANGE,
    [PerformanceChangesByOptionValue.Peak_14_days]: PerformanceChangeField.DOWNLOAD_DAY_14_CHANGE,
    [PerformanceChangesByOptionValue.Peak_30_days]: PerformanceChangeField.DOWNLOAD_DAY_30_CHANGE,
  },
  [PerformanceEffectType.DAU]: {
    [PerformanceChangesByOptionValue.Impact_7_days]: PerformanceChangeField.DAU_IMPACT_DAY_7_CHANGE,
    [PerformanceChangesByOptionValue.Impact_14_days]: PerformanceChangeField.DAU_IMPACT_DAY_14_CHANGE,
    [PerformanceChangesByOptionValue.Impact_30_days]: PerformanceChangeField.DAU_IMPACT_DAY_30_CHANGE,
    [PerformanceChangesByOptionValue.Peak_1_days]: PerformanceChangeField.DAU_DAY_1_CHANGE,
    [PerformanceChangesByOptionValue.Peak_7_days]: PerformanceChangeField.DAU_DAY_7_CHANGE,
    [PerformanceChangesByOptionValue.Peak_14_days]: PerformanceChangeField.DAU_DAY_14_CHANGE,
    [PerformanceChangesByOptionValue.Peak_30_days]: PerformanceChangeField.DAU_DAY_30_CHANGE,
  },
  [PerformanceEffectType.MAU]: {
    [PerformanceChangesByOptionValue.Impact_7_days]: PerformanceChangeField.MAU_MONTH_1_CHANGE,
    [PerformanceChangesByOptionValue.Impact_14_days]: PerformanceChangeField.MAU_MONTH_1_CHANGE,
    [PerformanceChangesByOptionValue.Impact_30_days]: PerformanceChangeField.MAU_MONTH_1_CHANGE,
    [PerformanceChangesByOptionValue.Peak_1_days]: PerformanceChangeField.MAU_MONTH_1_CHANGE,
    [PerformanceChangesByOptionValue.Peak_7_days]: PerformanceChangeField.MAU_MONTH_1_CHANGE,
    [PerformanceChangesByOptionValue.Peak_14_days]: PerformanceChangeField.MAU_MONTH_1_CHANGE,
    [PerformanceChangesByOptionValue.Peak_30_days]: PerformanceChangeField.MAU_MONTH_1_CHANGE,
  },
}
