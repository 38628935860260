import { FC } from 'react'
import { Trans } from 'react-i18next'

import { Info } from '@mui/icons-material'
import { Box, Divider, Typography } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { useInternalAccessCheck } from '../../../account/hooks/roleHooks'
import { MotivationKeyWithDescription, MotivationTypeWithDescription } from '../../../market-explorer/types/MotivationType'
import { MotivationArchetypeAndTypeBadges } from '../../../motivations/components/MotivationArchetypeAndTypeBadges/MotivationArchetypeAndTypeBadges'

type LiveEventMotivationsSectionProps = {
  motivations?: MotivationTypeWithDescription[]
  archetypes?: MotivationKeyWithDescription[]
  alignLeft?: boolean
}

export const LiveEventMotivationsSection: FC<LiveEventMotivationsSectionProps> = ({ motivations, archetypes, alignLeft }) => {
  const internalUser = useInternalAccessCheck() // NOTE: Remove Internal role check when we want to release Live Event Motivations

  return (
    <>
      {internalUser && ((motivations?.length ?? 0) > 0 || (archetypes?.length ?? 0) > 0) && (
        <>
          <Divider sx={{ my: 2 }}>
            <Trans i18nKey="live-events:archetypes_and_motivations_title" />
            <GRTooltip
              content={
                <Typography variant="body1" component="div">
                  <Trans i18nKey={'live-events:archetypes_and_motivations_title_tooltip'} />
                </Typography>
              }
            >
              <Info color="primary" sx={{ position: 'relative', top: '4px', marginLeft: '4px', fontSize: '18px' }} />
            </GRTooltip>
          </Divider>

          <Box mb={1}>
            <MotivationArchetypeAndTypeBadges motivations={motivations} archetypes={archetypes} alignLeft={alignLeft} />
          </Box>
        </>
      )}
    </>
  )
}
