import {
  endOfDay,
  differenceInCalendarDays,
  startOfMonth,
  sub,
  eachMonthOfInterval,
  isSameMonth,
  startOfDay,
  eachDayOfInterval,
  getISODay,
  areIntervalsOverlapping,
  differenceInMilliseconds,
  isWithinInterval,
  add,
  endOfYesterday,
  Interval,
} from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { average } from '../../../helpers/average'
import { endOfPreviousDay, endOfPreviousMonth, formatISODate, isValidInterval, startOfNextDay } from '../../../helpers/date'
import { uniq } from '../../../helpers/uniq'
import utilsService from '../../../services/UtilsService'
import { useCurrentMarket } from '../../markets'
import { eventStatisticsTimeStartMin } from '../const/const'
import {
  EventTypeStats,
  LiveEventCounts,
  LiveEventTypeDurationCategory,
  PerformanceByEventType,
  PerformanceByGameAndEventType,
  RollingPerformance,
  TrackingEventsByDurationCategory,
} from '../types/LiveEventStatistics'
import { FilteredTrackingEventsByGame, TrackingEventsByEventType } from '../types/TrackingEvents'
import { convertTrackingEventToDurationCategory, getEventStatDurationChange } from '../utils/utils'
import { useLiveEventTagGroupsMapByEventTypeId } from './useLiveEventTagGroups'
import { useTrackedGamesEvents } from './useTrackedGamesEvents'

/**
 * Hook for counting daily event counts for given games
 */
type UseEventCountsHookParams = {
  trackingEventsByGame: FilteredTrackingEventsByGame
  timeStart: number
  timeEnd: number
}

export const useEventCounts = ({ trackingEventsByGame, timeStart, timeEnd }: UseEventCountsHookParams) => {
  const interval: Interval = { start: timeStart, end: timeEnd }
  if (isValidInterval(interval)) {
    const days = eachDayOfInterval(interval)
    const trackedGamesCount = Object.keys(trackingEventsByGame.events).length

    const eventCounts = days.reduce((acc, day) => {
      const dayAsInterval = { start: startOfDay(day), end: endOfDay(day) }
      Object.values(trackingEventsByGame.events)
        .flatMap((trackingEvents) => trackingEvents)
        .forEach((trackingEvent) => {
          if (areIntervalsOverlapping(dayAsInterval, { start: trackingEvent.start, end: trackingEvent.end })) {
            acc[day.getTime()] = acc[day.getTime()] ? acc[day.getTime()] + 1 : 1
          } else {
            acc[day.getTime()] = acc[day.getTime()] || 0
          }
        })

      return acc
    }, {} as LiveEventCounts)

    const eventCountAverages = Object.entries(eventCounts).reduce((acc, [day, count]) => {
      acc[+day] = count / trackedGamesCount

      return acc
    }, {} as LiveEventCounts)

    return eventCountAverages
  }
}

/**
 * Hook for fetching event statistics for given games and time range. Calculates change values
 * from a same length time range before the requested time range
 */
export const useEventStatistics = ({ trackingEventsByGame, timeStart, timeEnd }: UseEventStatisticsHookParams) => {
  const eventStatistics = useEventStatisticsByTimeRange({ trackingEventsByGame, timeStart, timeEnd })
  const referenceTimeStart = (timeStart || 0) - ((timeEnd || 0) - (timeStart || 0))
  const referenceTimeEnd = endOfPreviousDay(timeStart)
  const referenceEventStatistics = useEventStatisticsByTimeRange({ trackingEventsByGame, timeStart: referenceTimeStart, timeEnd: referenceTimeEnd })
  const liveEventTagGroupsMapByEventTypeId = useLiveEventTagGroupsMapByEventTypeId()

  const enrichedEventstatistics = Object.entries(eventStatistics).reduce((acc, [eventTypeId, stats]) => {
    const referenceStats = referenceEventStatistics[eventTypeId]
    const popularityChange = stats.popularity.value - (referenceStats?.popularity.value || 0)
    const popularityPercentChange = popularityChange / (referenceStats?.popularity.value || 0)
    const [maxDurationChange, maxDurationPercentChange] = getEventStatDurationChange(stats, referenceStats, 'max')
    const [avgDurationChange, avgDurationPercentChange] = getEventStatDurationChange(stats, referenceStats, 'avg')
    const revenueChange = stats.revenue.value - (referenceStats?.revenue.value || 0)
    const revenuePercentChange = revenueChange / (referenceStats?.revenue.value || 0) || 0

    stats.popularity.change = popularityChange
    stats.popularity.percentChange = popularityPercentChange
    stats.duration.max.change = maxDurationChange
    stats.duration.max.percentChange = maxDurationPercentChange
    stats.duration.avg.change = avgDurationChange
    stats.duration.avg.percentChange = avgDurationPercentChange
    stats.revenue.change = revenueChange
    stats.revenue.percentChange = revenuePercentChange
    stats.colorHex = liveEventTagGroupsMapByEventTypeId[eventTypeId] ? liveEventTagGroupsMapByEventTypeId[eventTypeId].colorHex : ''

    return {
      ...acc,
      [eventTypeId]: stats,
    }
  }, {} as EventTypeStats)

  return Object.values(enrichedEventstatistics)
}

/**
 * Hook for assembling statistical data for all events in selected games
 */
type UseEventStatisticsHookParams = {
  trackingEventsByGame: FilteredTrackingEventsByGame
  timeStart: number
  timeEnd: number
}

export const useEventStatisticsByTimeRange = ({ trackingEventsByGame, timeStart, timeEnd }: UseEventStatisticsHookParams) => {
  const timeRangeInterval: Interval = useMemo(() => ({ start: timeStart, end: timeEnd }), [timeEnd, timeStart])
  const trackedGamesCount = Object.keys(trackingEventsByGame.events).length

  const eventsByEventType = useTrackingEventsByEventType({ trackingEventsByGame, interval: timeRangeInterval })
  const revenueByGameAndTime = usePerformanceByGameAndTimeLookupTable({ trackingEventsByGame })
  const performanceByEventType = getEventTypePerformanceInInterval({
    interval: timeRangeInterval,
    eventsByEventType,
    revenueByGameAndTime,
  })

  const eventStatistics = Object.entries(eventsByEventType).reduce((acc, [eventTypeId, trackingEvents]) => {
    const popularity = uniq(trackingEvents.map((trackingEvent) => trackingEvent.gameId)).length / trackedGamesCount
    const durations = trackingEvents.map((trackingEvent) =>
      differenceInMilliseconds(startOfNextDay(trackingEvent.end), startOfDay(trackingEvent.start).getTime())
    )

    const duration = {
      max: { value: { start: 0, end: Math.max(...durations) }, change: 0, percentChange: 0 },
      avg: { value: { start: 0, end: Math.round(average(durations)) }, change: 0, percentChange: 0 },
    }

    const eventTypeRevenue = performanceByEventType[eventTypeId].revenue
    const revenue = { value: eventTypeRevenue, change: 0, percentChange: 0 }

    return {
      ...acc,
      [eventTypeId]: { eventTypeId, popularity: { value: popularity, change: 0, percentChange: 0 }, duration, revenue },
    } as EventTypeStats
  }, {} as EventTypeStats)

  return eventStatistics
}

/**
 * Calculates a series of moving averages within given interval and window size (rollingDays) for performance values.
 * Calculation has been sliced to block to avoid UI blocking
 */
type EventTypeRollingPerformanceHookParams = {
  interval: Interval
  rollingDays?: number
  trackingEventsByGame: Partial<FilteredTrackingEventsByGame>
  eventTypeId: string
}
export const useEventTypeRollingPerformance = ({ interval, rollingDays = 14, trackingEventsByGame, eventTypeId }: EventTypeRollingPerformanceHookParams) => {
  const eventsByEventType = useTrackingEventsByEventType({ trackingEventsByGame: trackingEventsByGame as FilteredTrackingEventsByGame, interval, eventTypeId })
  const revenueByGameAndTime = usePerformanceByGameAndTimeLookupTable({ trackingEventsByGame: trackingEventsByGame as FilteredTrackingEventsByGame })

  const blockSize = 5
  const [rollingSeries, setRollingSeries] = useState<RollingPerformance[]>([])
  const [daysCounter, setDaysCounter] = useState<number>(0)
  const daySeries = useMemo(() => {
    const daysInInterval = eachDayOfInterval(interval)
    return daysInInterval.filter((day, index, array) => array.length - index >= rollingDays + 1)
  }, [interval, rollingDays])

  // count the values in blocks of *blockSize*
  useEffect(() => {
    if (daysCounter * blockSize < daySeries.length - 1 + blockSize && Object.keys(eventsByEventType).length > 0) {
      const days = daySeries.slice(daysCounter * blockSize, daysCounter * blockSize + blockSize)
      const timeout = setTimeout(() => {
        const rollingSeriesPart = days.map((day) => {
          const rollingInterval = { start: day, end: add(day, { days: rollingDays }) }
          const performance = getEventTypePerformanceInInterval({ interval: rollingInterval, eventsByEventType, revenueByGameAndTime })
          return {
            ...performance[eventTypeId],
            ts: +rollingInterval.end,
          }
        })
        setRollingSeries((value) => {
          const newValue = [...value]
          newValue.splice(daysCounter * blockSize, 0, ...rollingSeriesPart)
          return newValue
        })
      }, 10)

      return () => clearTimeout(timeout)
    }
  }, [daySeries, daysCounter, eventTypeId, eventsByEventType, revenueByGameAndTime, rollingDays])

  useEffect(() => {
    if (rollingSeries[daysCounter * blockSize]) {
      setDaysCounter((value) => value + 1)
    }
  }, [daysCounter, rollingSeries])

  const isLoading = rollingSeries.length !== daySeries.length

  return {
    rollingPerformance: isLoading ? [] : rollingSeries,
    isLoading,
  }
}

/**
 *  Organize performance values for efficient lookup by game and date
 */
type RevenueByGameAndTimeLookupTableHookParams = {
  trackingEventsByGame: FilteredTrackingEventsByGame
}

const usePerformanceByGameAndTimeLookupTable = ({ trackingEventsByGame }: RevenueByGameAndTimeLookupTableHookParams) =>
  useMemo(
    () =>
      Object.entries(trackingEventsByGame.performanceValues || {}).reduce((byGame, [gameId, performanceValues]) => {
        byGame[gameId] =
          performanceValues?.reduce((byDate, estimateValue) => {
            byDate = { ...byDate, [estimateValue.date]: { revenue: estimateValue.revenue, downloads: estimateValue.downloads } }
            return byDate
          }, {} as { [date: string]: { revenue: number; downloads: number } }) || {}
        return byGame
      }, {} as PerformanceByGameAndEventType),
    [trackingEventsByGame.performanceValues]
  )

/**
 * Map tracking events by event type
 */
type TrackingEventsByEventTypeHookParams = {
  trackingEventsByGame: FilteredTrackingEventsByGame
  interval: Interval
  eventTypeId?: string
}
const useTrackingEventsByEventType = ({ trackingEventsByGame, interval, eventTypeId }: TrackingEventsByEventTypeHookParams) =>
  useMemo(
    () =>
      Object.values(trackingEventsByGame.events)
        .flatMap((trackingEvents) => trackingEvents)
        .filter((trackingEvent) => (eventTypeId ? trackingEvent.typeId === eventTypeId : true))
        .filter((trackingEvent) =>
          isValidInterval(interval) ? areIntervalsOverlapping(interval, { start: trackingEvent.start, end: trackingEvent.end }) : false
        )
        .reduce((acc, trackingEvent) => {
          if (acc[trackingEvent.typeId]) {
            acc[trackingEvent.typeId] = [...acc[trackingEvent.typeId], trackingEvent]
          } else {
            acc[trackingEvent.typeId] = [trackingEvent]
          }

          return acc
        }, {} as TrackingEventsByEventType),
    [eventTypeId, interval, trackingEventsByGame.events]
  )

/**
 * Hook for calculating monthly popularity values for events with given event type and games.
 * Current month is included in the calculations if at least a week (7 days) has passed.
 */
type EventTypeMonthlyStatisticsForGamesHookParams = {
  trackedGameIds: string[]
  eventTypeId: string
  months?: number
}

const defaultMonthlyStatisticsMonths = 3
export const useEventTypeMonthlyStatisticsForGames = ({
  trackedGameIds,
  eventTypeId,
  months = defaultMonthlyStatisticsMonths,
}: EventTypeMonthlyStatisticsForGamesHookParams): [{ [month: number]: number }, boolean] => {
  const { currentMarketIso } = useCurrentMarket()

  const currentTime = endOfDay(new Date())
  const isPastFirstWeekofCurrentMonth = differenceInCalendarDays(currentTime, startOfMonth(currentTime)) >= 7
  const startTimestamp = isPastFirstWeekofCurrentMonth
    ? startOfMonth(sub(currentTime, { months: months })).getTime()
    : startOfMonth(sub(endOfPreviousMonth(currentTime), { months: months - 1 })).getTime()
  const endTimestamp = isPastFirstWeekofCurrentMonth ? currentTime.getTime() : endOfPreviousMonth(currentTime)
  const limitedTimeRange = useEventStatisticsLimitedTimeRange({ timeStart: startTimestamp, timeEnd: endTimestamp })

  const trackedEvents = useTrackedGamesEvents({
    gameIds: trackedGameIds,
    startTimestamp: limitedTimeRange.start,
    endTimestamp: limitedTimeRange.end,
    marketIso: currentMarketIso,
  })

  // this needs to be returned "games" length as user might not have permission to see all games
  const gameCount = Object.keys(trackedEvents.events).length || trackedGameIds.length

  // get months within (limited) time range
  const monthsOfInterval = eachMonthOfInterval({ start: limitedTimeRange.start, end: limitedTimeRange.end })

  const gamesImplementingEventTypeMonthly = Object.entries(trackedEvents.events).reduce((acc, [gameId, events]) => {
    monthsOfInterval.forEach((month) => {
      acc[month.getTime()] = acc[month.getTime()] || 0
      events.every((event) => {
        if (event.typeId === eventTypeId && (isSameMonth(month, event.start) || isSameMonth(month, event.end))) {
          acc[month.getTime()] = acc[month.getTime()] ? acc[month.getTime()] + 1 : 1
          return false
        }

        return true
      })
    })

    return acc
  }, {} as { [month: number]: number })

  const monthlyEventStats = Object.entries(gamesImplementingEventTypeMonthly).reduce((acc, [month, amount]) => {
    acc[+month] = amount / gameCount

    return acc
  }, {} as { [month: number]: number })

  return [monthlyEventStats, trackedEvents.isLoading]
}

/**
 * Hook for calculating daily popularity values for events with given event type and games.
 * Counting ends to the end of the previous day.
 */
type EventTypeDailyStatisticsForGamesHookParams = {
  trackedGameIds: string[]
  eventTypeId: string
  days?: number
}
const daysInWeek = 7
const defaultDailyStatisticsDays = daysInWeek * 13 // 13 weeks
export const useEventTypeDailyStatisticsForGames = ({
  trackedGameIds,
  eventTypeId,
  days = defaultDailyStatisticsDays,
}: EventTypeDailyStatisticsForGamesHookParams): [number[], boolean] => {
  const { currentMarketIso } = useCurrentMarket()

  const endTimestamp = endOfDay(sub(new Date(), { days: 1 })).getTime()
  const startTimestamp = startOfDay(sub(endTimestamp, { days: days })).getTime()
  const limitedTimeRange = useEventStatisticsLimitedTimeRange({ timeStart: startTimestamp, timeEnd: endTimestamp })

  const trackedEvents = useTrackedGamesEvents({
    gameIds: trackedGameIds,
    startTimestamp: limitedTimeRange.start,
    endTimestamp: limitedTimeRange.end,
    marketIso: currentMarketIso,
  })

  if (!trackedEvents.isLoading && !trackedEvents.events) {
    return [[], false]
  }

  const eventsFilteredByEventType = Object.values(trackedEvents.events)
    .flatMap((events) => events)
    .filter((event) => event.typeId === eventTypeId)

  const dailyEventStats = eventsFilteredByEventType
    .reduce((acc, event) => {
      const eventInterval = { start: startOfDay(event.start), end: endOfDay(event.end) }
      // take only the first 7 days to avoid longer events from being calculated multiple times
      const eventDays = eachDayOfInterval(eventInterval).slice(0, daysInWeek)
      eventDays.forEach((eventDay) => {
        const weekday = getISODay(eventDay) - 1
        acc[weekday] = acc[weekday] + 1
      })

      return acc
    }, new Array(7).fill(0))
    .map((weekdayCount) => weekdayCount / eventsFilteredByEventType.length)

  return [dailyEventStats, trackedEvents.isLoading]
}

type EventStatisticsLimitedTimeRangeHookParams = {
  timeStart: number
  timeEnd: number
}

/**
 * Hook that limits given time range between 15.2.2023 and end of yesterday
 */
export const useEventStatisticsLimitedTimeRange = ({ timeStart, timeEnd }: EventStatisticsLimitedTimeRangeHookParams) =>
  useMemo(() => {
    const max = endOfYesterday().getTime()
    const limitedInterval = { start: eventStatisticsTimeStartMin, end: max }
    if (timeStart <= eventStatisticsTimeStartMin && timeEnd <= eventStatisticsTimeStartMin) {
      return { start: eventStatisticsTimeStartMin, end: eventStatisticsTimeStartMin }
    } else if (timeStart <= eventStatisticsTimeStartMin && isWithinInterval(timeEnd, limitedInterval)) {
      return { start: eventStatisticsTimeStartMin, end: timeEnd }
    } else if (isWithinInterval(timeStart, limitedInterval) && timeEnd >= max) {
      return { start: timeStart, end: max }
    } else if (timeStart <= eventStatisticsTimeStartMin && timeEnd >= max) {
      return { start: eventStatisticsTimeStartMin, end: max }
    } else if (timeStart >= max && timeEnd >= max) {
      return { start: max, end: max }
    } else {
      return { start: timeStart, end: timeEnd }
    }
  }, [timeEnd, timeStart])

/**
 * Calculate performance average values in given interval for all event types
 */
const getEventTypePerformanceInInterval = ({
  interval,
  eventsByEventType,
  revenueByGameAndTime,
}: {
  interval: Interval
  eventsByEventType: TrackingEventsByEventType
  revenueByGameAndTime: ReturnType<typeof usePerformanceByGameAndTimeLookupTable>
}) => {
  const days = isValidInterval(interval) ? eachDayOfInterval(interval) : []
  const eventRevenueAndDownloadsByEventType = Object.entries(eventsByEventType).reduce((acc, [eventTypeId, trackingEvents]) => {
    trackingEvents.forEach((event) => {
      let eventRevenue = { in: 0, out: 0 }
      let eventDownloads = { in: 0, out: 0 }
      days.forEach((day) => {
        const formattedDate = formatISODate(day)
        const { revenue: dailyRevenue = 0, downloads: dailyDownloads = 0 } = revenueByGameAndTime[event.gameId]?.[formattedDate] || {}

        if (isWithinInterval(day, { start: startOfDay(event.start), end: endOfDay(event.end) })) {
          eventRevenue = { in: eventRevenue.in + dailyRevenue, out: eventRevenue.out }
          eventDownloads = { in: eventDownloads.in + dailyDownloads, out: eventDownloads.out }
        } else {
          eventRevenue = { in: eventRevenue.in, out: eventRevenue.out + dailyRevenue }
          eventDownloads = { in: eventDownloads.in, out: eventDownloads.out + dailyDownloads }
        }
      })

      // we neglect events that last the whole inspected time range (out performance is 0)
      acc[eventTypeId] = {
        revenue:
          eventRevenue.out !== 0 && eventRevenue.in !== 0
            ? [...(acc[eventTypeId]?.revenue || []), eventRevenue.in / eventRevenue.out]
            : acc[eventTypeId]?.revenue || [],
        downloads:
          eventDownloads.out !== 0 && eventDownloads.in !== 0
            ? [...(acc[eventTypeId]?.downloads || []), eventDownloads.in / eventDownloads.out]
            : acc[eventTypeId]?.downloads || [],
      }
    })
    return acc
  }, {} as PerformanceByEventType)

  // count averages for all values
  const performanceByEventType = Object.entries(eventRevenueAndDownloadsByEventType).reduce((acc, [eventTypeId, eventRevenueAndDownloads]) => {
    acc[eventTypeId] = { revenue: average(eventRevenueAndDownloads.revenue) || 0, downloads: average(eventRevenueAndDownloads.downloads) || 0 }

    return acc
  }, {} as { [eventTypeId: string]: { revenue: number; downloads: number } })

  return performanceByEventType
}

/**
 * Hook for preparing data for durations chart
 */
type DurationsChartDataHookParams = {
  trackingEventsByDurationCategory: TrackingEventsByDurationCategory
}

const datasetColors = utilsService.getRGBChartColorList()
export const useDurationsChartData = ({ trackingEventsByDurationCategory }: DurationsChartDataHookParams) => {
  const { t } = useTranslation()
  return useMemo(() => {
    const eventCount = Object.values(trackingEventsByDurationCategory).flat().length
    const durationStats = Object.entries(trackingEventsByDurationCategory).reduce((acc, [category, trackingEvents]) => {
      if (trackingEvents.length > 0) {
        acc[category as LiveEventTypeDurationCategory] = trackingEvents.length / eventCount
      }

      return acc
    }, {} as { [key in LiveEventTypeDurationCategory]: number })

    const dataPoints = Object.entries(durationStats)
      .sort(([aCategory, aShare], [bCategory, bShare]) => bShare - aShare)
      .reduce(
        (acc, [category, categoryShare]) => {
          acc.labels.push(t(`live-events:duration_stats_category_${category.toLowerCase()}`))
          acc.data.push(categoryShare)
          return acc
        },
        { labels: [], data: [] } as { labels: string[]; data: number[] }
      )

    return {
      labels: dataPoints.labels,
      datasets: [
        {
          data: dataPoints.data,
          backgroundColor: datasetColors,
        },
      ],
    }
  }, [t, trackingEventsByDurationCategory])
}

/**
 * Hook for extracting and categorizing tracking events in duration categories
 */
type LiveEventTypeDurationCategoriesHookParams = {
  trackedGameIds: string[]
  eventTypeId: string
  dateRange: Interval
}
export const useLiveEventTypeDurationCategories = ({ trackedGameIds, eventTypeId, dateRange }: LiveEventTypeDurationCategoriesHookParams) => {
  const { currentMarketIso } = useCurrentMarket()

  const trackedEventsByGame = useTrackedGamesEvents({
    gameIds: trackedGameIds,
    startTimestamp: +dateRange.start,
    endTimestamp: +dateRange.end,
    marketIso: currentMarketIso,
    eventTypeId,
  })

  const trackedEvent = Object.values(trackedEventsByGame.events).flat()

  const trackingEventsByDurationCategory = useMemo(() => {
    return trackedEvent.reduce((acc, event) => {
      const durationCategory = convertTrackingEventToDurationCategory(event)
      acc[durationCategory] = acc[durationCategory] ? [...acc[durationCategory], event] : [event]

      return acc
    }, {} as TrackingEventsByDurationCategory)
  }, [trackedEvent])

  return {
    trackingEventsByDurationCategory,
    isLoading: trackedEventsByGame.isLoading,
  }
}
