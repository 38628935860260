import React from 'react'

import { Box } from '@mui/material'

import { MotivationKeyWithDescription, MotivationTypeWithDescription } from '../../../market-explorer/types/MotivationType'
import MotivationArchetypeBadge from '../MotivationArchetypeBadge/MotivationArchetypeBadge'
import { MotivationTypeBadge } from '../MotivationTypeBadge/MotivationTypeBadge'

interface Props {
  motivations?: MotivationTypeWithDescription[]
  archetypes?: MotivationKeyWithDescription[]
  alignLeft?: boolean
}

export const MotivationArchetypeAndTypeBadges: React.FC<Props> = ({ motivations, archetypes, alignLeft }) => {
  return (
    <Box className="ArchetypesAndMotivationTypeBadges" textAlign={alignLeft ? 'left' : 'center'}>
      <Box>
        {motivations?.map((motivation) => {
          return <MotivationTypeBadge type={motivation.key} description={motivation.description ? motivation.description : undefined} alignLeft={alignLeft} />
        })}
      </Box>

      <Box mb={2}>
        {archetypes?.map((archetype) => {
          return <MotivationArchetypeBadge motivationKey={archetype.key} description={archetype.description} />
        })}
      </Box>
    </Box>
  )
}
