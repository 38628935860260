import { t } from 'i18next'
import { FC, useRef, useMemo, useState, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AddReaction, Delete, Edit, EmojiEmotions } from '@mui/icons-material'
import { TableContainer, Card, Grid, Typography } from '@mui/material'

import ConfirmDialog from '../../../../../components/ConfirmDialog/ConfirmDialog'
import { GRTableColumn, GRTable } from '../../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../../components/GRTooltip/GRTooltip'
import { useQueryStatusSnackbarMessage } from '../../../../../hooks/useQueryStatusSnackbarMessage'
import { useRequiredParams } from '../../../../../hooks/useRequiredParams'
import { ConfirmDialogData } from '../../../../../types/ConfirmDialogData'
import { useCloneEventsMutation, useDeleteTrackedGameEventByDateMutation } from '../../../../api/internal'
import { ActionButton } from '../../../../components/ActionButton/ActionButton'
import { CheckedStatusCircle } from '../../../../components/CheckedStatusCircle/CheckedStatusCircle'
import { EventCloner } from '../../../../components/EventCloner/EventCloner'
import { InternalTrackingEventByDate } from '../../../../types/InternalTrackingEvent'
import { useLiveEventsTrackingEventsSearchParams } from '../../hooks/useLiveEventsTrackingEventsSearchParams'

/**
 * Table component for displaying daily events for a tracked game
 */
type TrackedGameDailyEventsTableProps = {
  events?: InternalTrackingEventByDate[]
  isLoading?: boolean
}

type EventDeleteConfirmData = {
  trackingEventId: string
  date: Date
}

export const TrackedGameDailyEventsTable: FC<TrackedGameDailyEventsTableProps> = ({ events, isLoading }) => {
  const containerRef = useRef(null)
  const navigate = useNavigate()
  const { trackedGameId } = useRequiredParams<{ trackedGameId: string }>()
  const { parsedParams } = useLiveEventsTrackingEventsSearchParams()
  const [cloneEvents, cloneEventsMutation] = useCloneEventsMutation()
  const [deleteEvent] = useDeleteTrackedGameEventByDateMutation()
  const [deleteEventsDialogData, setDeleteEventDialogData] = useState<ConfirmDialogData<EventDeleteConfirmData>>()

  const dailyEventsColumns: GRTableColumn<InternalTrackingEventByDate, typeof customTableProps>[] = useMemo(
    () => [
      {
        headerCellProps: { sx: { minWidth: 240, textAlign: 'left' } },
        labelAccessor: () => <Trans i18nKey="internal-common:name" />,
        cellProps: { sx: { fontWeight: 700, textAlign: 'left' } },
        accessor: ({ row }) => row.name,
        sortable: true,
        sortAccessor: ({ row }) => row.name,
      },
      {
        headerCellProps: { sx: { minWidth: 240, textAlign: 'left' } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:event_type" />,
        cellProps: { sx: { textAlign: 'left' } },
        accessor: ({ row }) => row.type,
        sortable: true,
        sortAccessor: ({ row }) => row.type,
      },
      {
        headerCellProps: { sx: { minWidth: 80 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:category" />,
        accessor: ({ row }) => row.category,
        sortable: true,
      },
      {
        headerCellProps: { sx: { minWidth: 80 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:active" />,
        accessor: ({ row }) => <CheckedStatusCircle value={row.active} />,
        sortable: true,
        sortAccessor: ({ row }) => (row.active ? 1 : 0),
      },
      {
        headerCellProps: { sx: { minWidth: 80 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:highlighted" />,
        accessor: ({ row }) => <CheckedStatusCircle value={row.highlighted} />,
        sortable: true,
        sortAccessor: ({ row }) => (row.highlighted ? 1 : 0),
      },
      {
        headerCellProps: { sx: { width: 160 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:actions" />,
        accessor: ({ row, customTableProps }) => (
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              <ActionButton onClick={() => navigate(`event/${row.eventId}/edit`)}>
                <Edit fontSize="small" />
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton color="warning" onClick={() => handleDeleteEvent({ trackingEventId: row.eventId, date: customTableProps!.date })}>
                <Delete fontSize="small" />
              </ActionButton>
            </Grid>
            <Grid item>
              <GRTooltip content={`${t('internal-common:motivations')}: ${row.analyzed ? t('game-header:analyzed') : t('internal-common:not_analyzed')}`}>
                <ActionButton onClick={() => navigate(`event/${row.eventId}/analysis`)} color={row.analyzed ? 'success' : 'primary'}>
                  {row.analyzed ? <EmojiEmotions fontSize="small" /> : <AddReaction fontSize="small" />}
                </ActionButton>
              </GRTooltip>
            </Grid>
          </Grid>
        ),
      },
    ],
    [navigate]
  )

  const handleCloneEvents = (date: Date) => {
    cloneEvents({
      gameId: trackedGameId,
      sourceDate: {
        start: date.getTime(),
        end: date.getTime(),
      },
      targetDate: {
        start: parsedParams.date.getTime(),
        end: parsedParams.date.getTime(),
      },
    })
  }

  const handleDeleteEvent = (data: EventDeleteConfirmData) => {
    setDeleteEventDialogData({
      title: t('internal-live-events:delete_game_event_from_date_confirm_dialog_title'),
      confirmText: t('internal-live-events:delete_game_event_from_date_confirm_dialog_confirm_text'),
      actionText: t('internal-common:yes'),
      cancelText: t('internal-common:no'),
      destructiveAction: true,
      data,
    })
  }

  const handleDeleteEventConfirm = (dialogData?: ConfirmDialogData<EventDeleteConfirmData>) => {
    if (dialogData?.data) {
      deleteEvent({ trackingEventId: dialogData.data.trackingEventId, date: dialogData.data.date.getTime() })
    }

    setDeleteEventDialogData(undefined)
  }

  useQueryStatusSnackbarMessage({
    ...cloneEventsMutation,
    successMessage: t('internal-live-events:clone_events_success_message'),
  })

  const customTableProps = useMemo(() => {
    return {
      date: parsedParams.date,
    }
  }, [parsedParams.date])

  const [columns, setColumns] = useState<GRTableColumn<InternalTrackingEventByDate, typeof customTableProps>[]>(dailyEventsColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<InternalTrackingEventByDate, typeof customTableProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <>
      <TableContainer component={Card} ref={containerRef}>
        <GRTable
          columns={columns}
          isLoading={isLoading}
          onColumnsUpdated={handleColumnsUpdate}
          rowIdKey={(event) => event.eventId}
          rows={events || []}
          customProps={customTableProps}
          scroller={containerRef}
          striped
          gridlines
          noRowsLabel={
            <Grid container direction="column" alignItems="center" rowSpacing={2}>
              <Grid item>
                <Typography variant="body1">{t('internal-live-events:no_events_for_selected_date_help_text')}</Typography>
              </Grid>
              <Grid item>
                <EventCloner onClone={(date) => handleCloneEvents(date)} />
              </Grid>
            </Grid>
          }
        />
      </TableContainer>
      <ConfirmDialog open={!!deleteEventsDialogData} onClose={handleDeleteEventConfirm} confirmDialogData={deleteEventsDialogData} />
    </>
  )
}
