import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareDefaultHeaders } from '../../api'
import { AppType } from '../../features/game/types/Game'

const REDUCER_PATH = 'internalOpenAIChatApi'

export interface GetAIAnalystAnswerQueryParams {
  prompt: string
}

export const internalOpenAIChatApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: window.GR_API_URLS.API_URL_AI_ANALYST_INTERNAL,
    prepareHeaders: prepareDefaultHeaders,
  }),
  tagTypes: ['liveEventAnalysis'],
  endpoints: (builder) => ({
    getLiveEventDescription: builder.mutation<
      { appType: AppType; eventDescription: string },
      { appName: string; appType: AppType; eventName: string; screenshotUrls: string[] }
    >({
      query: ({ appName, appType, eventName, screenshotUrls }) => ({
        url: 'imagesEvent',
        method: 'POST',
        body: {
          appName,
          appType,
          eventName,
          screenshotUrls,
        },
      }),
    }),
  }),
})

export const { useGetLiveEventDescriptionMutation } = internalOpenAIChatApi
