import React, { lazy, Suspense, useCallback, useEffect, useRef } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { ArrowBack, ArrowUpward, CopyAll, DeleteForever, Refresh, RotateLeft, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, CardContent, CardHeader, Divider, Fade, Grid, Link, Skeleton, Typography, Zoom } from '@mui/material'

import GRCircularProgress from '../../../components/GRCircularProgress/GRCircularProgress'
import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'
import { GameCardContent } from '../../../features/game/components/GameCard/GameCard'
import { MotivationKey, MotivationType } from '../../../features/market-explorer/types/MotivationType'
import MotivationArchetypeBadge from '../../../features/motivations/components/MotivationArchetypeBadge/MotivationArchetypeBadge'
import { MotivationTypeBadge } from '../../../features/motivations/components/MotivationTypeBadge/MotivationTypeBadge'
import { motivationArchetypeImages } from '../../../features/motivations/utils/MotivationArchetypeImages'
import { MotivationIcon } from '../../../features/motivations/utils/svgs'
import { displaySnackBar } from '../../../features/snackbar'
import { useAppDispatch } from '../../../hooks/storeHooks'
import { useRequiredParams } from '../../../hooks/useRequiredParams'
import languageService from '../../../services/LanguageService'
import { useGetTrackedGameEventQuery, useGetTrackedGameQuery } from '../../api/internal'
import { useGetMotivationAnalysisQuery, useGetMotivationDescriptionQuery, usePostLiveEventAnalysisMutation } from '../../api/internal-combined'
import { ActionButton } from '../../components/ActionButton/ActionButton'
import { Analyst } from '../../features/live-events-tracking/components/TrackedGamesTable/TrackedGamesTable'
import { LiveEventAnalysisMutation } from '../../types/types'

const HTMLEditor = lazy(() => import('../../components/HTMLEditor/HTMLEditor').then((module) => ({ default: module.HTMLEditor })))
const yOffset = -105

const TrackedGameEventAnalysisPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { trackedGameId, eventId } = useRequiredParams<{ trackedGameId: string; eventId: string }>()
  const trackedGameQuery = useGetTrackedGameQuery(trackedGameId)
  const trackedGameEventQuery = useGetTrackedGameEventQuery(eventId, { skip: !eventId })

  const {
    data: motivationDescription,
    isLoading: isLoadingMotivationDescription,
    isFetching: isFetchingMotivationDescription,
    isError: isErrorMotivationDescription,
    refetch: refetchMotivationDescription,
  } = useGetMotivationDescriptionQuery(
    { description: trackedGameEventQuery.data?.event.comment.content.comment?.en },
    { skip: !trackedGameEventQuery.data?.event.comment.content.comment?.en }
  )
  const [postLiveEventData, { isError: isErrorPostLiveEventData, isSuccess: isSuccessPostLiveEventData }] = usePostLiveEventAnalysisMutation()
  const { data: motivationAnalysis } = useGetMotivationAnalysisQuery({ eventId }, { skip: !eventId })

  const getMotivationName = useCallback((key: MotivationKey | MotivationType) => {
    return languageService.getTranslation('motivations', key)
  }, [])

  useEffect(() => {
    if (isErrorMotivationDescription) {
      dispatch(displaySnackBar({ message: t('internal-live-events:error_could_not_load_openai_description'), severity: 'error', open: true }))
    }

    if (isErrorPostLiveEventData) {
      dispatch(displaySnackBar({ message: t('internal-live-events:error_could_not_save_motivations'), severity: 'error', open: true }))
    }

    if (isSuccessPostLiveEventData) {
      dispatch(displaySnackBar({ message: t('internal-live-events:success_motivations_saved'), severity: 'success', open: true }))
    }
  }, [dispatch, t, isErrorMotivationDescription, isErrorPostLiveEventData, isSuccessPostLiveEventData])

  const descriptionForm = useForm<LiveEventAnalysisMutation>({
    defaultValues: {
      eventId: eventId,
      motivations: [],
      archetypes: [],
    },
  })

  const {
    fields: fieldsMotivations,
    append: appendMotivation,
    remove: removeMotivation,
  } = useFieldArray({
    control: descriptionForm.control,
    name: 'motivations',
  })

  const {
    fields: fieldsArchetypes,
    append: appendArchetype,
    remove: removeArchetype,
  } = useFieldArray({
    control: descriptionForm.control,
    name: 'archetypes',
  })

  useEffect(() => {
    if (motivationAnalysis) {
      descriptionForm.reset({
        eventId: eventId,
        motivations: motivationAnalysis.motivations || [],
        archetypes: motivationAnalysis.archetypes || [],
      })
    }
  }, [motivationAnalysis, eventId, descriptionForm])

  const handleRefreshSuggestion = useCallback(() => {
    return () => {
      refetchMotivationDescription()
    }
  }, [refetchMotivationDescription])

  const isActive = useCallback(
    (motivationOrActhetypeKey: MotivationKey | MotivationType) => {
      const values = descriptionForm.getValues()
      return (
        !!values.motivations.find((motivation) => motivation.key === motivationOrActhetypeKey) ||
        !!values.archetypes.find((archetype) => archetype.key === motivationOrActhetypeKey)
      )
    },
    [descriptionForm]
  )

  const onSubmit = (data: any) => {
    postLiveEventData(data)
  }

  const handleBackToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const renderEditor = (field: any, index: number, type: 'motivations' | 'archetypes') => {
    const control = descriptionForm.control

    return (
      <Card key={field.id} sx={{ marginBottom: 2 }} id={`${type}-editor-${field.id}`}>
        <CardHeader
          title={
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Grid item marginRight={1} display="flex" alignContent="center">
                    {type === 'motivations' && <MotivationIcon motivation={field.key} />}
                    {type === 'archetypes' && <img height="24px" width="24px" src={motivationArchetypeImages[field.key]} alt={getMotivationName(field.key)} />}
                  </Grid>
                  <Grid item>
                    <Typography variant="h3">{getMotivationName(field.key)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item mr={1}>
                    <GRTooltip content={t('internal-common:copy_suggestion')}>
                      <ActionButton
                        onClick={() => {
                          // copy current suggestion into the editor
                          const suggestion = motivationDescription?.motivationSuggestion.replace(/<\/?[^>]+(>|$)/g, '') || ''
                          descriptionForm.setValue(`${type}.${index}.description`, suggestion, {
                            shouldDirty: true,
                          })
                        }}
                      >
                        <CopyAll fontSize="small" />
                      </ActionButton>
                    </GRTooltip>
                  </Grid>

                  <Grid item>
                    <GRTooltip content={t('internal-common:remove_description')}>
                      <ActionButton
                        color="warning"
                        onClick={() => {
                          if (type === 'motivations') {
                            removeMotivation(index)
                          }

                          if (type === 'archetypes') {
                            removeArchetype(index)
                          }

                          handleBackToTop()
                        }}
                      >
                        <DeleteForever fontSize="small" />
                      </ActionButton>
                    </GRTooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <Divider />
        <CardContent>
          <Controller
            name={`${type}.${index}.description`}
            control={control}
            render={({ field }) => (
              <Suspense fallback={<GRCircularProgress />}>
                <HTMLEditor
                  value={field.value}
                  onEditorChange={(content) =>
                    descriptionForm.setValue(`${type}.${index}.description`, content, {
                      shouldDirty: true,
                    })
                  }
                  init={{
                    height: 250,
                    menubar: false,
                    convert_urls: false,
                    block_formats: 'Paragraph=p;',
                    plugins: 'lists',
                    toolbar: 'undo redo removeformat | blocks blockquote | outdent indent | bold italic underline bullist | code',
                  }}
                />
              </Suspense>
            )}
          />
        </CardContent>
      </Card>
    )
  }

  const motivationSuggestionIsLoading = isLoadingMotivationDescription || isFetchingMotivationDescription
  const itemRef = useRef(null)

  return (
    <Box>
      <Card sx={{ marginBottom: 2 }}>
        <CardHeader
          title={
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>{trackedGameQuery.data?.game && <GameCardContent game={trackedGameQuery.data?.game} disableGameLink />}</Grid>
              <Grid item sx={{ transform: 'translateX(-25%)' }}>
                <Typography variant="h3">{trackedGameEventQuery.data?.event.name}</Typography>
              </Grid>
              {trackedGameEventQuery.data?.analyst && (
                <Grid item>
                  <Zoom in={!!trackedGameEventQuery.data?.analyst} timeout={500}>
                    <Box>
                      <Analyst analyst={trackedGameEventQuery.data?.analyst} avatarProps={{ sx: { width: 60, height: 60 } }} />
                    </Box>
                  </Zoom>
                </Grid>
              )}
            </Grid>
          }
        />
        <Divider />
        <CardContent sx={{ position: 'relative', minHeight: 80 }}>
          {!motivationSuggestionIsLoading && motivationDescription?.motivationSuggestion && (
            <Fade in={!motivationSuggestionIsLoading} timeout={500}>
              <Box marginRight={6}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: motivationDescription?.motivationSuggestion,
                  }}
                ></div>
              </Box>
            </Fade>
          )}

          {motivationSuggestionIsLoading && (
            <Fade in={motivationSuggestionIsLoading} timeout={500}>
              <Box>
                <Skeleton variant="text" width={500} />
                <Skeleton variant="text" width={300} />
                <Skeleton variant="text" width={200} sx={{ marginBottom: 2 }} />
                <Skeleton variant="text" width={600} />
                <Skeleton variant="text" width={450} />
                <Skeleton variant="text" width={550} />
                <Skeleton variant="text" width={400} />
              </Box>
            </Fade>
          )}

          <GRTooltip content={t('internal-common:refresh_suggestion')} anchorRef={itemRef}>
            <LoadingButton
              variant="contained"
              color="primary"
              ref={itemRef}
              loading={motivationSuggestionIsLoading}
              onClick={handleRefreshSuggestion()}
              sx={{ position: 'absolute', top: 20, right: 20, width: 32, minWidth: 32, height: 32, minHeight: 32 }}
            >
              <Refresh />
            </LoadingButton>
          </GRTooltip>

          {motivationDescription?.uuid && !motivationSuggestionIsLoading && (
            <Box
              sx={{
                fontSize: 10,
                backgroundColor: '#f9f9f9',
                color: '#c9c9c9',
                borderTopLeftRadius: 10,
                border: '1px solid #eee',
                borderBottom: 'none',
                borderRight: 'none',
                position: 'absolute',
                padding: '6px',
                bottom: 0,
                right: 0,
              }}
            >{`uuid: ${motivationDescription.uuid}`}</Box>
          )}
        </CardContent>
        <Divider />
        <CardContent>
          <Link
            underline="none"
            variant="h3"
            display="inline-block"
            mt={2}
            mb={2}
            onClick={(e) => {
              e.preventDefault()
              const element = document.getElementById('motivations-section')
              if (element) {
                const y = element.getBoundingClientRect().top + window.scrollY + yOffset
                window.scrollTo({ top: y, behavior: 'smooth' })
              }
            }}
          >
            <Trans i18nKey="internal-common:motivations" />
          </Link>
          <Box>
            {Object.values(MotivationType).map((motivationType) => {
              return (
                <MotivationTypeBadge
                  description={fieldsMotivations.find((motivation) => motivation.key === motivationType)?.description}
                  type={motivationType}
                  active={isActive(motivationType)}
                  onSelect={(motivationType) => {
                    if (fieldsMotivations.some((motivation) => motivation.key === motivationType)) {
                      const field = fieldsMotivations.find((motivation) => motivation.key === motivationType)
                      if (!field) return

                      const element = document.getElementById(`motivations-editor-${field.id}`)
                      if (!element) return

                      const y = element.getBoundingClientRect().top + window.scrollY + yOffset
                      window.scrollTo({ top: y, behavior: 'smooth' })

                      const index = fieldsMotivations.findIndex((motivation) => motivation.key === motivationType)

                      if (!index) return
                      descriptionForm.setFocus(`motivations.${index}.description`, { shouldSelect: true })
                      return
                    }

                    appendMotivation({ key: motivationType as MotivationType, description: '' })
                  }}
                />
              )
            })}
          </Box>

          <Link
            underline="none"
            variant="h3"
            display="block"
            mt={2}
            mb={2}
            onClick={(e) => {
              e.preventDefault()
              const element = document.getElementById('archetypes-section')
              if (element) {
                const y = element.getBoundingClientRect().top + window.scrollY + yOffset
                window.scrollTo({ top: y, behavior: 'smooth' })
              }
            }}
          >
            <Trans i18nKey="internal-common:archetypes" />
          </Link>
          <Box>
            {Object.values(MotivationKey).map((motivationKey) => {
              return (
                <MotivationArchetypeBadge
                  key={motivationKey}
                  active={isActive(motivationKey)}
                  motivationKey={motivationKey}
                  description={fieldsArchetypes.find((archetype) => archetype.key === motivationKey)?.description}
                  onSelect={(motivationKey) => {
                    if (fieldsArchetypes.some((archetype) => archetype.key === motivationKey)) {
                      const field = fieldsArchetypes.find((archetype) => archetype.key === motivationKey)
                      if (!field) return

                      const element = document.getElementById(`archetypes-editor-${field.id}`)
                      if (!element) return

                      const y = element.getBoundingClientRect().top + window.scrollY + yOffset
                      window.scrollTo({ top: y, behavior: 'smooth' })
                      return
                    }

                    appendArchetype({ key: motivationKey as MotivationKey, description: '' })
                  }}
                />
              )
            })}
          </Box>
        </CardContent>
      </Card>

      <FormProvider {...descriptionForm}>
        <form onSubmit={descriptionForm.handleSubmit(onSubmit)}>
          {/* EDITORS */}
          {fieldsMotivations.length > 0 && (
            <Typography variant="h3" mb={2} mt={2} id="motivations-section">
              <Trans i18nKey="internal-common:motivations" />
            </Typography>
          )}
          {fieldsMotivations.map((field, index) => renderEditor(field, index, 'motivations'))}

          {fieldsArchetypes.length > 0 && (
            <Typography variant="h3" mb={2} id="archetypes-section">
              <Trans i18nKey="internal-common:archetypes" />
            </Typography>
          )}
          {fieldsArchetypes.map((field, index) => renderEditor(field, index, 'archetypes'))}

          {/* TOOLBAR */}
          <Card sx={{ marginTop: 2, position: 'sticky', bottom: 20, zIndex: 2 }} elevation={5}>
            <CardContent>
              <Grid container justifyContent="space-between">
                <Grid item display="flex" alignItems="center">
                  <Button variant="text" color="primary" onClick={() => window.history.back()} startIcon={<ArrowBack />}>
                    <Trans i18nKey={`internal-common:back`} />
                  </Button>

                  <Divider orientation="vertical" variant="middle" flexItem sx={{ marginRight: 2, marginLeft: 2 }} />

                  <GRTooltip content="Back to top">
                    <ActionButton onClick={handleBackToTop}>
                      <ArrowUpward fontSize="small" />
                    </ActionButton>
                  </GRTooltip>
                </Grid>

                <Grid item display="flex">
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<RotateLeft />}
                    onClick={() => descriptionForm.reset()}
                    disabled={!descriptionForm.formState.isDirty}
                  >
                    <Trans i18nKey={`internal-common:reset`} />
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Save />}
                    sx={{ marginLeft: 2 }}
                    type="submit"
                    disabled={!descriptionForm.formState.isValid}
                  >
                    <Trans i18nKey={`internal-common:save`} />
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </FormProvider>
    </Box>
  )
}

export default TrackedGameEventAnalysisPage
