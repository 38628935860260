import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@mui/system'

import { AllOrNothingSelector } from '../../../../components/AllOrNothingSelector/AllOrNothingSelector'
import { GRAccordion } from '../../../../components/GRAccordion/GRAccordion'
import { isObjectEmpty } from '../../../../helpers/isObjectEmpty'
import { MotivationKey, MotivationType } from '../../../market-explorer/types/MotivationType'
import MotivationArchetypeBadge from '../../../motivations/components/MotivationArchetypeBadge/MotivationArchetypeBadge'
import { MotivationTypeBadge } from '../../../motivations/components/MotivationTypeBadge/MotivationTypeBadge'

type MotivationSelection = { [id: string]: boolean }
interface Props {
  selectableMotivations: MotivationType[]
  selectedMotivationsIdsMap: { [motivationType: string]: boolean }
  selectableArchetypes: MotivationKey[]
  selectedArchetypesIdsMap: { [motivationKey: string]: boolean }
  onLiveEventsMotivationSelectChange: (newSelection: MotivationSelection, motivationType: MotivationType) => void
  onLiveEventsMotivationsSelectionCleared: () => void
  onLiveEventsMotivationsSelectAll: () => void
  onLiveEventsArchetypeSelectChange: (newSelection: MotivationSelection, motivationKey: MotivationKey) => void
  isLoading?: boolean
  open?: boolean
}

const LiveEventsMotivationsSelector: React.FC<Props> = ({
  selectableMotivations,
  selectedMotivationsIdsMap,
  selectableArchetypes,
  selectedArchetypesIdsMap,
  onLiveEventsMotivationSelectChange,
  onLiveEventsMotivationsSelectionCleared,
  onLiveEventsMotivationsSelectAll,
  onLiveEventsArchetypeSelectChange,
  isLoading,
  open,
}) => {
  const { t } = useTranslation()
  const [liveEventMotivationsListOpen, setLiveEventMotivationsListOpen] = useState(open || false)

  const handleMotivationClick = (motivationType: MotivationType) => {
    if (selectedMotivationsIdsMap[motivationType]) {
      const { [motivationType]: omitted, ...newSelection } = selectedMotivationsIdsMap
      onLiveEventsMotivationSelectChange(newSelection, motivationType)
    } else {
      const newSelection = { ...selectedMotivationsIdsMap }
      newSelection[motivationType] = true
      onLiveEventsMotivationSelectChange(newSelection, motivationType)
    }
  }

  const handleArchetypeClick = (motivationKey: MotivationKey) => {
    if (selectedArchetypesIdsMap[motivationKey]) {
      const { [motivationKey]: omitted, ...newSelection } = selectedArchetypesIdsMap
      onLiveEventsArchetypeSelectChange(newSelection, motivationKey)
    } else {
      const newSelection = { ...selectedArchetypesIdsMap }
      newSelection[motivationKey] = true
      onLiveEventsArchetypeSelectChange(newSelection, motivationKey)
    }
  }

  return (
    <div className="LiveEventsMotivationsSelector">
      <GRAccordion
        expanded={liveEventMotivationsListOpen}
        onChange={() => setLiveEventMotivationsListOpen(!liveEventMotivationsListOpen)}
        title={t('live-events:live_events_motivations_filter_title')}
        extra={
          <AllOrNothingSelector
            someSelected={!isObjectEmpty(selectedMotivationsIdsMap) || !isObjectEmpty(selectedArchetypesIdsMap)}
            deselectAll={onLiveEventsMotivationsSelectionCleared}
            selectAll={onLiveEventsMotivationsSelectAll}
            disabled={isLoading || !liveEventMotivationsListOpen || (selectableMotivations.length === 0 && selectableArchetypes.length === 0)}
          />
        }
      >
        <Box mt={1}>
          {selectableMotivations.map((motivation) => {
            return <MotivationTypeBadge type={motivation} selected={selectedMotivationsIdsMap[motivation]} onSelect={handleMotivationClick} alignLeft />
          })}
        </Box>

        <Box>
          {selectableArchetypes.map((motivationKey) => {
            return <MotivationArchetypeBadge motivationKey={motivationKey} selected={selectedArchetypesIdsMap[motivationKey]} onSelect={handleArchetypeClick} />
          })}
        </Box>
      </GRAccordion>
    </div>
  )
}

export default LiveEventsMotivationsSelector
