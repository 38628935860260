import { Business, Event, Group, Mail, Person } from '@mui/icons-material'

import NavigationIconCompareGames from '../assets/images/navigation-icon-compare-games.svg'
import NavigationIconDailyInsights from '../assets/images/navigation-icon-daily-insights.svg'
import NavigationIconFollowedGames from '../assets/images/navigation-icon-followed-games.svg'
import NavigationIconFTUEVideos from '../assets/images/navigation-icon-ftue-videos.svg'
import NavigationIconGameAnalyzer from '../assets/images/navigation-icon-game-analyzer.svg'
import NavigationIconGameUpdateImpacts from '../assets/images/navigation-icon-game-update-impacts.svg'
import NavigationIconGenreEssentials from '../assets/images/navigation-icon-genre-essentials.svg'
import NavigationIconImplementationExamples from '../assets/images/navigation-icon-implementation-examples.svg'
import NavigationIconLiveEventsTracker from '../assets/images/navigation-icon-live-events-tracker.svg'
import NavigationIconMarketExplorer from '../assets/images/navigation-icon-market-explorer.svg'
import NavigationIconMarketShare from '../assets/images/navigation-icon-market-share.svg'
import NavigationIconMarketTrends from '../assets/images/navigation-icon-market-trends.svg'
import NavigationIconReports from '../assets/images/navigation-icon-reports.svg'
import NavigationIconSoftLaunch from '../assets/images/navigation-icon-soft-launch.svg'
import NavigationIconTopGrossing from '../assets/images/navigation-icon-top-grossing.svg'
import NavigationIconVisualExplorer from '../assets/images/navigation-icon-visual-explorer.svg'
import SlackIcon from '../assets/images/slack-icon.svg'
import { RoleEnum } from '../features/account'
import { Page } from '../types/Page'
import { SideNavigationGroupData } from '../types/SideNavigationGroupData'
import NavigationIconAIAnalyst from './../assets/images/navigation-icon-ai-analyst.svg'

class PageService {
  pages: Array<Page>
  internalPages: Array<Page>

  constructor() {
    this.pages = [
      new Page('data-glossary-detail', '/data-glossary/*', 'sidebar:data_glossary', 'sidebar', ''),
      new Page('data-glossary', '/data-glossary', 'sidebar:data_glossary', 'sidebar', ''),
      new Page('daily-insights', '/daily-insights', 'sidebar:daily_insights', 'sidebar', 'daily-data', NavigationIconDailyInsights),
      new Page(
        'genre-essentials',
        '/genre-essentials/*',
        'sidebar:genre_essentials',
        'sidebar',
        'daily-data',
        NavigationIconGenreEssentials,
        undefined,
        'genre-essentials'
      ),
      new Page('top-grossing', '/top-grossing', 'sidebar:topgrossing', 'sidebar', 'daily-data', NavigationIconTopGrossing),
      new Page('soft-launch', '/soft-launch', 'sidebar:soft_launch', 'sidebar', 'daily-data', NavigationIconSoftLaunch),

      new Page('market-explorer', '/market-explorer/:subpage/*', 'sidebar:market_explorer', '', ''),
      new Page('market-explorer', '/market-explorer', 'sidebar:market_explorer', 'sidebar', 'market-research', NavigationIconMarketExplorer),
      new Page('market-share', '/market-share/:subpage', 'sidebar:market_share', '', ''),
      new Page('market-share', '/market-share', 'sidebar:market_share', 'sidebar', 'market-research', NavigationIconMarketShare),
      new Page(
        'visual-explorer',
        '/visual-explorer/*',
        'sidebar:visuals_explorer',
        'sidebar',
        'market-research',
        NavigationIconVisualExplorer,
        undefined,
        '/visual-explorer'
      ),
      new Page('report-detail', '/reports/:reportId/*', 'sidebar:reports', '', ''),
      new Page('reports', '/reports/*', 'sidebar:reports', 'sidebar', 'market-research', NavigationIconReports, undefined, '/reports'),

      new Page('market-trends', '/market-trends', 'sidebar:market-trends', 'sidebar', 'market-research', NavigationIconMarketTrends),
      new Page('market-trends', '/market-trends/*', 'sidebar:market-trends', '', ''),
      new Page('live-events-tracker', '/live-events-tracker/:subpage', 'sidebar:live_events_tracker', ''),
      new Page(
        'live-events-tracker',
        '/live-events-tracker',
        'sidebar:live_events_tracker',
        'sidebar',
        'market-research',
        NavigationIconLiveEventsTracker,
        undefined,
        '/live-events-tracker/feed'
      ),
      new Page(
        'ai-analyst',
        '/ai-analyst',
        'sidebar:ai_analyst',
        'sidebar',
        'market-research',
        NavigationIconAIAnalyst,
        undefined,
        undefined,
        RoleEnum.ai_analyst,
        true
      ),

      new Page('followed-games', '/followed-games', 'sidebar:followed_games', 'sidebar', 'game-analysis', NavigationIconFollowedGames),
      new Page('compare-games', '/compare-games/:subpage/*', 'sidebar:game_comparison', '', ''),
      new Page('compare-games', '/compare-games', 'sidebar:game_comparison', 'sidebar', 'game-analysis', NavigationIconCompareGames),
      new Page(
        'game-update-impacts',
        '/game-update-impacts/*',
        'sidebar:game-update-impacts',
        'sidebar',
        'game-analysis',
        NavigationIconGameUpdateImpacts,
        undefined,
        '/game-update-impacts'
      ),

      new Page('implementation-examples', '/implementation-examples/*', 'sidebar:impl_examples', '', ''),
      new Page(
        'implementation-examples',
        '/implementation-examples',
        'sidebar:impl_examples',
        'sidebar',
        'game-analysis',
        NavigationIconImplementationExamples
      ),

      new Page('ftue-videos', '/ftue-videos', 'sidebar:ftue-videos', 'sidebar', 'game-analysis', NavigationIconFTUEVideos),
      new Page('ftue-videos', '/ftue-videos/*', 'sidebar:ftue-videos', '', ''),

      new Page('game-analyzer-detail', '/game-analyzer/:id/*', 'sidebar:game_analyzer', '', ''),
      new Page(
        'game-analyzer',
        '/game-analyzer/*',
        'sidebar:game_analyzer',
        'sidebar',
        'game-analysis',
        NavigationIconGameAnalyzer,
        undefined,
        '/game-analyzer'
      ),

      new Page('news-card', '/news-card/:id', 'newsfeed:newscard', '', ''),

      new Page('game-tab', '/game/:id/:subpage/*', 'common:game', '', ''),
      new Page('game', '/game/:id', 'common:game', '', ''),

      new Page('my-profile', '/my-profile', 'profile:my_profile', 'account', '', '', Person),
      new Page('organization', '/organization', 'organization:organization', 'account', '', '', Business),
      new Page('organization-users', '/users', 'organization:users', 'account', '', '', Group),
      new Page('slack', '/slack', 'newsfeed:slack', 'account', '', SlackIcon),
      new Page('slack-preferences', '/slack/preferences/:teamName/:teamId/:channel/*', 'newsfeed:slack_preferences', '', ''),
      new Page('invites', '/invites', 'profile:invites', 'account', '', '', Mail),
    ]

    this.internalPages = [
      new Page(
        'internal-tracked-games',
        '/internal/live-events-tracking',
        'internal-sidebar:live_events_tracking',
        'analysts',
        'analysts',
        '',
        Event,
        undefined,
        [RoleEnum.internal, RoleEnum.let_internal],
        undefined,
        'internal-sidebar:live_events_tracking_tracked_games'
      ),
      new Page('internal-tracked-game-events', '/internal/live-events-tracking/events/:trackedGameId', 'internal-sidebar:live_events_tracking_events', ''),
      new Page(
        'internal-tracked-game-events-edit-event',
        '/internal/live-events-tracking/events/:trackedGameId/event/:eventId?/edit',
        'internal-sidebar:live_events_tracking_events',
        ''
      ),
      new Page('internal-tracked-games-search', '/internal/live-events-tracking/gamesearch', 'internal-sidebar:live_events_tracking_track_new_game', ''),
      new Page(
        'internal-tracked-game-event-analysis',
        '/internal/live-events-tracking/events/:trackedGameId/event/:eventId?/analysis',
        'internal-sidebar:live_events_tracking_events',
        ''
      ),
      new Page(
        'internal-tracked-game-analyst-note-edit',
        '/internal/live-events-tracking/events/:trackedGameId/analyst-notes/edit/analyst-notes/',
        'internal-sidebar:live_events_analyst_notes',
        ''
      ),
      new Page(
        'internal-tracked-game-analyst-notes',
        '/internal/live-events-tracking/events/:trackedGameId/analyst-notes',
        'internal-sidebar:live_events_analyst_notes',
        ''
      ),
      new Page(
        'internal-tracked-game-events-overview',
        '/internal/live-events-tracking/events/:trackedGameId/overview',
        'internal-sidebar:live_events_overview',
        ''
      ),
    ]
  }

  getSideNavigationGroups() {
    return [
      new SideNavigationGroupData(
        'daily-data',
        'sidebar:daily_data',
        this.pages.filter((page) => page.groupId === 'daily-data')
      ),
      new SideNavigationGroupData(
        'market-research',
        'sidebar:market_research',
        this.pages.filter((page) => page.groupId === 'market-research')
      ),
      new SideNavigationGroupData(
        'game-analysis',
        'sidebar:game_analysis',
        this.pages.filter((page) => page.groupId === 'game-analysis')
      ),
    ]
  }

  getInternalSideNavigationGroups() {
    return [
      new SideNavigationGroupData(
        'analysts',
        'internal-sidebar:group_analysts',
        this.internalPages.filter((page) => page.groupId === 'analysts')
      ),
    ]
  }

  getPageWithId(pageId: string) {
    return this.pages.find((page) => page.id === pageId)
  }

  getInternalPageWithId(pageId: string) {
    return this.internalPages.find((page) => page.id === pageId)
  }
}

const pageService = new PageService()

export default pageService
