import { PricePoint } from '../../../internal/features/live-events-tracking/types/PricePoint'
import { Analyst } from '../../../types/Analyst'
import { UserLanguage } from '../../account/types/User'
import { MotivationKeyWithDescription, MotivationTypeWithDescription } from '../../market-explorer/types/MotivationType'
import { NewsEntryType } from '../../news'
import { NewsEntryFeature, NewsEntryScreenshot } from '../../news/types/UnifiedNewsEntry'
import { GameTopIAPs } from '../../top-IAP/types/GameTopIAPs'
import type { useFilteredTrackedGamesEvents } from '../hooks/useTrackedGamesEvents'

export type TrackingEvent = {
  eventId: string
  name: string
  typeId: string
  gameId: string
  start: number
  end: number
  active: boolean
  highlighted: boolean
  screenshotUrl?: string
  comment?: EventComment
  tags: string[]
  pricePoints?: PricePoint[]
  motivations?: LiveEventMotivations
}

export type PerformanceChange = {
  appId: number
  date: string // YYYY-MM-dd
  performanceChanges?: PerformanceChanges
}

export type EstimateValue = {
  appId: number
  date: string //YYYY-MM-dd
  revenue: number
  downloads: number
}

type VersionData = {
  appId: number
  versionReleaseDate: number
  version: string
}

export type LiveEventsFeatureData = {
  type: NewsEntryType
  screenshots?: NewsEntryScreenshot[]
  features?: NewsEntryFeature[]
}

export type LiveEventReview = {
  id: string
  active: boolean
  comment: {
    analystId: string
    analyst?: Analyst
    content: {
      comment: { [languageKey in UserLanguage]?: string }
      title: { [languageKey in UserLanguage]?: string }
    }
  }
  end: number
  start: number
  gameId: string
}

export type TrackingEventsByGame = {
  events: TrackingEventByGame
  extraEventData?: {
    [eventId: string]: { screenshotUrl: string; comment: EventComment; motivations: { motivations: []; archetypes: [] } }
  }
  performanceChanges?: { [gameId: string]: PerformanceChange[] | undefined }
  performanceValues?: { [gameId: string]: EstimateValue[] | undefined }
  versions?: { [gameId: string]: VersionData[] | undefined }
  featureData?: { [gameId: string]: { [versionNumber: string]: LiveEventsFeatureData } | undefined }
  topIAPs?: { [gameId: string]: GameTopIAPs[] | undefined }
  comments?: { [gameId: string]: LiveEventReview[] }
  analysts?: { [analystId: string]: Analyst }
}

export type FilteredTrackingEventsByGame = ReturnType<typeof useFilteredTrackedGamesEvents>

export type LiveTrackingEvent = {
  analyst: Analyst
  event: LiveTrackingEventEvent
  durations: Duration[]
  motivations?: { motivations: MotivationTypeWithDescription[]; archetypes: MotivationKeyWithDescription[] }
  filteredMotivations?: LiveEventMotivations
}

type LiveTrackingEventEvent = {
  id: string
  gameId: string
  appId: number
  name: string
  typeId: string
  eventId: number
  active: boolean
  screenshotUrls?: string[]
  comment: EventComment
  tags: string[]
  pricePoints?: PricePoint[]
}

export type Duration = {
  id: string
  createdAt: number
  modifiedAt: number
  createdBy: string
  modifiedBy: null
  liveEventId: string
  gameId: string
  start: number
  end: number
}

export type EventComment = {
  analystId: string
  content: EventCommentContent
}

type EventCommentContent = {
  comment?:
    | {
        [languageCode in UserLanguage]?: string
      }
    | null
  title?: { [languageKey in UserLanguage]?: string }
}

export type PerformanceChanges = { [key in PerformanceChangeField]?: number }

export enum PerformanceChangeField {
  REVENUE_DAY_1_CHANGE = 'REVENUE_DAY_1_CHANGE',
  DOWNLOAD_DAY_1_CHANGE = 'DOWNLOAD_DAY_1_CHANGE',
  DAU_DAY_1_CHANGE = 'DAU_DAY_1_CHANGE',
  REVENUE_DAY_7_CHANGE = 'REVENUE_DAY_7_CHANGE',
  DOWNLOAD_DAY_7_CHANGE = 'DOWNLOAD_DAY_7_CHANGE',
  DAU_DAY_7_CHANGE = 'DAU_DAY_7_CHANGE',
  REVENUE_DAY_14_CHANGE = 'REVENUE_DAY_14_CHANGE',
  DOWNLOAD_DAY_14_CHANGE = 'DOWNLOAD_DAY_14_CHANGE',
  DAU_DAY_14_CHANGE = 'DAU_DAY_14_CHANGE',
  REVENUE_DAY_30_CHANGE = 'REVENUE_DAY_30_CHANGE',
  DOWNLOAD_DAY_30_CHANGE = 'DOWNLOAD_DAY_30_CHANGE',
  DAU_DAY_30_CHANGE = 'DAU_DAY_30_CHANGE',
  REVENUE_IMPACT_DAY_7_CHANGE = 'REVENUE_IMPACT_DAY_7_CHANGE',
  REVENUE_IMPACT_DAY_14_CHANGE = 'REVENUE_IMPACT_DAY_14_CHANGE',
  REVENUE_IMPACT_DAY_30_CHANGE = 'REVENUE_IMPACT_DAY_30_CHANGE',
  DOWNLOAD_IMPACT_DAY_7_CHANGE = 'DOWNLOAD_IMPACT_DAY_7_CHANGE',
  DOWNLOAD_IMPACT_DAY_14_CHANGE = 'DOWNLOAD_IMPACT_DAY_14_CHANGE',
  DOWNLOAD_IMPACT_DAY_30_CHANGE = 'DOWNLOAD_IMPACT_DAY_30_CHANGE',
  DAU_IMPACT_DAY_7_CHANGE = 'DAU_IMPACT_DAY_7_CHANGE',
  DAU_IMPACT_DAY_14_CHANGE = 'DAU_IMPACT_DAY_14_CHANGE',
  DAU_IMPACT_DAY_30_CHANGE = 'DAU_IMPACT_DAY_30_CHANGE',
  MAU_MONTH_1_CHANGE = 'MAU_MONTH_1_CHANGE',
}

export type TrackingEventsByEventType = { [eventTypeId: string]: TrackingEvent[] }
export type TrackingEventByGame = { [gameId: string]: TrackingEvent[] }
export type LiveEventMotivations = { motivations: MotivationTypeWithDescription[]; archetypes: MotivationKeyWithDescription[] }
